import { Input, Stack, Text } from "@chakra-ui/react";
import { string } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const searchIMEIState = atom({
  key: "imei",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

type DeviceSearchBarProps = {
  onChange?: () => void;
};

export function DeviceSearchBar(props: DeviceSearchBarProps) {
  const [searchId, setSearchId] = useRecoilState(searchIMEIState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchId(e.target.value);
    props.onChange && props.onChange();
  };

  return (
    <Stack spacing={0}>
      <Text fontSize="12">Search</Text>
      <Input
        size="sm"
        placeholder="IMEI"
        value={searchId}
        onChange={handleChange}
      />
    </Stack>
  );
}
