import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { managerState } from "state/auth";
import { ROUTE_URI_LOGIN } from "utils/constants";
import { LocationState } from "../types/auth";

export const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();

  const manager = useRecoilValue(managerState);

  // 用户未登录
  if (!manager) {
    // 重定向至Login Page, 设置from state
    const state: LocationState = { from: location };

    return (
      <Navigate
        to={ROUTE_URI_LOGIN}
        state={{ from: state }}
      />
    );
  }

  return children;
};
