import { MainLayout } from "components/MainLayout";

import { RequireAuth } from "hooks";
import {
  Bios,
  Calls,
  Captchas,
  Dashboard,
  Devices,
  Gifts,
  Home,
  Login,
  Managers,
  Photos,
  Profiles,
  Receipts,
  Relationships,
  Reports,
  Secrets,
  Stories,
  Subscriptions,
  Transactions,
  Transfers,
  Notifications
} from "pages";
import { Profile } from "pages/Profile";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ROUTE_URI_BIOS,
  ROUTE_URI_CALLS,
  ROUTE_URI_CAPTCHAS,
  ROUTE_URI_DASHBOARD,
  ROUTE_URI_DEVICES,
  ROUTE_URI_GIFTS,
  ROUTE_URI_LOGIN,
  ROUTE_URI_MANAGERS,
  ROUTE_URI_PHOTOS,
  ROUTE_URI_PROFILES,
  ROUTE_URI_RECEIPTS,
  ROUTE_URI_RELATIONSHIPS,
  ROUTE_URI_REPORTS,
  ROUTE_URI_SECRETS,
  ROUTE_URI_STORIES,
  ROUTE_URI_TRANSACTIONS,
  ROUTE_URI_SUBSCRIPTIONS,
  ROUTE_URI_TRANSFER,
  ROUTE_URI_NOTIFICATION
  
} from "utils/constants/router.uri";

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />

        <Route
          path={ROUTE_URI_LOGIN}
          element={<Login />}
        />

        {/* Private */}
        <Route
          path="/"
          element={
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          }>
          <Route
            path={ROUTE_URI_DASHBOARD}
            element={<Dashboard />}
          />

          <Route
            path={ROUTE_URI_PHOTOS}
            element={<Photos />}
          />
          <Route
            path={ROUTE_URI_STORIES}
            element={<Stories />}
          />
          <Route
            path={ROUTE_URI_BIOS}
            element={<Bios />}
          />
          <Route
            path={ROUTE_URI_REPORTS}
            element={<Reports />}
          />

          <Route path={ROUTE_URI_PROFILES}>
            <Route
              index
              element={<Profiles />}
            />
            <Route
              path=":id"
              element={<Profile />}
            />
          </Route>
          <Route
            path={ROUTE_URI_DEVICES}
            element={<Devices />}
          />
          <Route
            path={ROUTE_URI_CALLS}
            element={<Calls />}
          />
          <Route
            path={ROUTE_URI_RELATIONSHIPS}
            element={<Relationships />}
          />
          <Route
            path={ROUTE_URI_GIFTS}
            element={<Gifts />}
          />

          <Route
            path={ROUTE_URI_RECEIPTS}
            element={<Receipts />}
          />
          <Route
            path={ROUTE_URI_TRANSACTIONS}
            element={<Transactions />}
          />
          <Route
            path={ROUTE_URI_SUBSCRIPTIONS}
            element={<Subscriptions />}
          />
          <Route
            path={ROUTE_URI_TRANSFER}
            element={<Transfers />}
          />
             <Route
            path={ROUTE_URI_NOTIFICATION}
            element={<Notifications />}
          />
          <Route
            path={ROUTE_URI_MANAGERS}
            element={<Managers />}
          />
          <Route
            path={ROUTE_URI_SECRETS}
            element={<Secrets />}
          />
          <Route
            path={ROUTE_URI_CAPTCHAS}
            element={<Captchas />}
          />
        </Route>

        {/* 404 */}
        <Route
          path="*"
          element={<p>404</p>}
        />
      </Routes>
    </BrowserRouter>
  );
}
