import { Heading, HStack, Spacer, StackProps } from "@chakra-ui/react";
import _ from "lodash";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { MediaStatus } from "types";
import {
  MediaStatusFilter,
  mediaStatusOptionState,
  MonthFilter,
} from "./filters";

export interface MainHeaderProps extends StackProps {
  totalState?: RecoilValueReadOnly<number>;
}

export function MainHeader({
  totalState,
  children,
  ...stackProps
}: MainHeaderProps) {
  return (
    <HStack
      spacing={2}
      p={4}
      position="sticky"
      top={0}
      bg={"white"}
      zIndex={3}
      alignItems="flex-end"
      {...stackProps}>
      <MainHeading />
      {totalState && (
        <Suspense fallback={<></>}>
          <TotalView totalState={totalState} />
        </Suspense>
      )}
      <Spacer />
      {children}
    </HStack>
  );
}

export function MainHeading() {
  const location = useLocation();

  const components = location.pathname.split("/");
  const heading = components[components.length - 1];

  return <Heading>{_.capitalize(heading)}</Heading>;
}

function TotalView({
  totalState,
}: {
  totalState: RecoilValueReadOnly<number>;
}) {
  const total = useRecoilValue(totalState);

  return <Heading>({total})</Heading>;
}

export function HeaderTrainingItems() {
  const mediaStatus = useRecoilValue(mediaStatusOptionState) as MediaStatus;

  return (
    <>
      <MediaStatusFilter />
      {mediaStatus !== MediaStatus.Pending && <MonthFilter />}
    </>
  );
}
