import { number } from "@recoiljs/refine";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { DeviceStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";

export const deviceStatuses = [
  DeviceStatus.All,
  DeviceStatus.Enabled,
  DeviceStatus.Disabled,
];

export const deviceStatusState = atom({
  key: "device_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function DeviceStatusFilter() {
  return (
    <FilterSelector
      label="Status"
      statusState={deviceStatusState}
      options={deviceStatuses.map((status, index) => {
        const option: SelectFilterOption = {
          label: DeviceStatus[status],
          value: index,
        };
        return option;
      })}
    />
  );
}
