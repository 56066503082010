import dayjs from "dayjs";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { ManagersParams, fetchManagers } from "services";
import { getErrorMessage } from "utils";
import { managerEmailState } from "./common";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const managersRequestId = atom({
  key: "managers/requestId",
  default: dayjs().valueOf(),
});

export const managersPageState = atom({
  key: "managers/page",
  default: 1,
});

export const managersRespQuery = selector({
  key: "managers/resp",
  get: async ({ get }) => {
    // 触发刷新
    get(managersRequestId);

    const page = get(managersPageState);
    const pageSize = get(pageSizeState);
    const managerEmail = get(managerEmailState);

    const params: ManagersParams = {
      page,
      pageSize,
      account: managerEmail,
    };

    try {
      const resp = await fetchManagers(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const managersQuery = selector({
  key: "managers",
  get: ({ get }) => get(managersRespQuery)?.data ?? [],
});

export const managersCountState = selector({
  key: "managers/count",
  get: ({ get }) => get(managersRespQuery)?.page?.total ?? 0,
});

export const managerQuery = selectorFamily({
  key: "managers",
  get:
    (id: number) =>
    ({ get }) =>
      (get(managersRespQuery)?.data ?? []).find((manager) => manager.id === id),
});
