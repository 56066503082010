export enum Gender {
  Woman,
  Man,
}

export enum ProfileStatus {
  Deleted = -1,
  Pending,
  Approved,
  Rejected,
  All = 99,
}

export enum BioStatus {
  Deleted,
  Pending,
  Rejected,
  Approved = 99,
}

export enum subStatus{
  All,
  AppStore = 1,
  GooglePlay = 2

}
