import {
  MonthParams,
  PageParams,
  RelationshipStatus,
  RelationshipType,
  Resp,
  Timestamp,
} from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export enum RelationshipSearchType {
  // Like
  LIKE_LIST = 1,
  LIKE,
  LIKED,
  // Block
  BLOCK_LIST,
  BLOCK,
  BLOCKED,
  // Follow
  FOLLOW_LIST,
  FOLLOWING,
  FOLLOWER,
}

export interface RelationshipsParams extends PageParams, MonthParams {
  uid?: number;
  type: RelationshipSearchType;
}

export interface Relationship {
  id: number;
  initiator: Profile;
  recipient: Profile;
  type: RelationshipType; // Random, Direct
  status: RelationshipStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type RelationshipsResp = Resp<Relationship[]>;

const API_URL_RELATIONSHIPS_SEARCH = "/relationship/search";

export const fetchRelationships = (params: RelationshipsParams) => {
  return privateAxios.post<RelationshipsResp>(
    API_URL_RELATIONSHIPS_SEARCH,
    params
  );
};
