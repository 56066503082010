import CryptoJS from "crypto-js";
import { privateAxios, publicAxios } from "services/apis/axios";
import { ManagerResp } from "./manager";

export const login = (email: string, password: string, token: string) => {
  const pwdMD5 = CryptoJS.MD5(password).toString();

  return publicAxios.post<ManagerResp>("/login/pwd", {
    token,
    account: email,
    password: pwdMD5,
  });
};

export const refreshToken = (refToken: string) => {
  return publicAxios.post<ManagerResp>("/refresh/token", { refToken });
};

export const logout = () => {
  return privateAxios.post("/login/out");
};
