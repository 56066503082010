import {
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import {
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  RelationshipTag,
  UIDSearchBar,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import { Suspense } from "react";
import {
  RecoilValueReadOnly,
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import { syncEffect } from "recoil-sync";
import { Relationship } from "services";
import {
  blockRelationshipsCountQuery,
  blockRelationshipsQuery,
  blocksPageState,
  followRelationshipsCountQuery,
  followRelationshipsQuery,
  followsPageState,
  likeRelationshipsCountQuery,
  likeRelationshipsQuery,
  likesPageState,
} from "state";
import { timestampMsToDateStr } from "utils";

export const relationshipsTabState = atom<number>({
  key: "relationships_tab",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

const tabs = [
  {
    label: "Following",
    state: followRelationshipsQuery,
    pageState: followsPageState,
    totalState: followRelationshipsCountQuery,
  },
  {
    label: "Like",
    state: likeRelationshipsQuery,
    pageState: likesPageState,
    totalState: likeRelationshipsCountQuery,
  },
  {
    label: "Block",
    state: blockRelationshipsQuery,
    pageState: blocksPageState,
    totalState: blockRelationshipsCountQuery,
  },
];

export function Relationships() {
  const [tabIndex, setTabIndex] = useRecoilState(relationshipsTabState);

  const tab = tabs[tabIndex];

  const resetPage = useResetRecoilState(tab.pageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={tab.totalState}>
        <UIDSearchBar onChange={resetPage} />
        <MonthFilter />
      </MainHeader>

      <Tabs
        isLazy
        defaultIndex={tabIndex}
        onChange={(index) => setTabIndex(index)}>
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.label}>{t.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((t) => (
            <TabPanel key={t.label}>
              <Suspense fallback={<Spinner />}>
                <RelationshipsTable state={t.state} />
              </Suspense>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Spacer />
      <MainFooter>
        <Pagination
          state={tab.pageState}
          totalState={tab.totalState}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

type RelationshipsTableProps = {
  state: RecoilValueReadOnly<Relationship[]>;
};

function RelationshipsTable({ state }: RelationshipsTableProps) {
  const relationships = useRecoilValue(state);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {relationships.map((relationship) => (
            <Tr key={relationship.id}>
              <Td>{relationship.id}</Td>
              <Td>
                <ProfileBasicInfo profile={relationship.initiator} />
              </Td>
              <Td>
                <ProfileBasicInfo profile={relationship.recipient} />
              </Td>
              <Td>
                <RelationshipTag status={relationship.status} />
              </Td>
              <Td>{timestampMsToDateStr(relationship.createdAt)}</Td>
              <Td>{timestampMsToDateStr(relationship.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
