import { Box, Spacer, Spinner, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import {
  EmptyView,
  MainFooter,
  MainHeader,
  MediaStatusFilter,
  MonthFilter,
  Pagination,
  StoryView,
  SubmitButton,
  mediaStatusOptionState,
} from "components";
import { Suspense } from "react";
import { RecoilState, RecoilValueReadOnly, useRecoilValue } from "recoil";
import { reviewStories } from "services";
import {
  rejectedStoriesIdsState,
  storiesCountQuery,
  storiesPageState,
  storiesQuery,
  storiesRequestIdState,
  storiesToSubmitQuery,
} from "state";
import { Story } from "types";

export function Stories() {
  const mediaStatusOption = useRecoilValue(mediaStatusOptionState);

  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={storiesCountQuery}>
        {mediaStatusOption !== 0 && <MonthFilter />}
        <MediaStatusFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner size="sm" />}>
          <StoriesWarp
            state={storiesQuery}
            rejectedStoriesIdsState={rejectedStoriesIdsState}
          />
        </Suspense>
      </Box>

      <Spacer />

      <MainFooter>
        <Pagination
          state={storiesPageState}
          totalState={storiesCountQuery}
        />
        <Spacer />
        {[0, 1].includes(mediaStatusOption) && (
          <SubmitButton
            state={storiesToSubmitQuery}
            requestIdState={storiesRequestIdState}
            rejectedIdsState={rejectedStoriesIdsState}
            api={reviewStories}
          />
        )}
      </MainFooter>
    </Stack>
  );
}

export function StoriesWarp({
  state,
  rejectedStoriesIdsState,
}: {
  state: RecoilValueReadOnly<Story[]>;
  rejectedStoriesIdsState: RecoilState<number[]>;
}) {
  const stories = useRecoilValue(state);

  return (
    <Stack>
      {stories.length === 0 ? (
        <EmptyView />
      ) : (
        <Wrap>
          {stories.map((story) => (
            <WrapItem key={story.id}>
              <StoryView
                w="188px"
                story={story}
                rejectedStoriesIdsState={rejectedStoriesIdsState}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Stack>
  );
}
