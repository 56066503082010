import { Badge } from "@chakra-ui/react";
import { ProfileStatus } from "types";

export function ProfileStatusBadge({ status }: { status: ProfileStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case ProfileStatus.Pending:
        return "yellow";
      case ProfileStatus.Approved:
        return "green";
      case ProfileStatus.Rejected:
        return "red";
      case ProfileStatus.Deleted:
        return "gray";
    }
  };
  return <Badge colorScheme={getColorScheme()}>{ProfileStatus[status]}</Badge>;
}
