import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { errorState } from "state/error";

export function ErrorToast() {
  const [error, setError] = useRecoilState(errorState);
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: error,
        status: "error",
      });
    }
    return () => {
      setError(undefined);
    };
  });
  return <></>;
}
