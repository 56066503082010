import { privateAxios } from "services";
import { PageParams, Resp, Story } from "types";

export type ReportReason = {
  id: number;
  content: string;
  count: number;
};

export interface ReportedStory {
  reasons: ReportReason[];
  story: Story;
  uid: number;
}

export type ReportedStoriesResp = Resp<ReportedStory[]>;

const API_URL_REPORTED_STORIES_SEARCH = "/report/story/search";
const API_URL_REPORTED_STORIES_REVIEW = "/report/story/confirm";

export interface ReportedStoriesParams extends PageParams {}

export const fetchReportedStories = (params: ReportedStoriesParams) => {
  return privateAxios.post<ReportedStoriesResp>(
    API_URL_REPORTED_STORIES_SEARCH,
    params
  );
};

export type ReviewReportedStoriesBody = {
  storyIds: number[];
};

export const reviewReportedStories = (body: ReviewReportedStoriesBody) => {
  return privateAxios.post(API_URL_REPORTED_STORIES_REVIEW, body);
};

export const reviewReportedStory = (id: number) => {
  return reviewReportedStories({ storyIds: [id] });
};
