import { Tag } from "@chakra-ui/react";
import { RelationshipStatus } from "types";

export function RelationshipTag({ status }: { status: RelationshipStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case RelationshipStatus.Enable:
        return "green";

      case RelationshipStatus.Disable:
        return "gray";
    }
  };
  return <Tag colorScheme={getColorScheme()}>{RelationshipStatus[status]}</Tag>;
}
