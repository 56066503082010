import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { CaptchasSearchParams, fetchCaptchas } from "services";
import { getErrorMessage } from "utils";

import dayjs from "dayjs";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const captchasPageState = atom({
  key: "captchas/page",
  default: 1,
});

export const captchasRequestIdState = atom({
  key: "captchas/requestId",
  default: dayjs().valueOf(),
});

export const searchAccountState = atom({
  key: "account",
  default: "",
});

export const captchasRespQuery = selector({
  key: "captchas/resp",
  get: async ({ get }) => {
    get(captchasRequestIdState);

    const page = get(captchasPageState);
    const pageSize = get(pageSizeState);

    const params: CaptchasSearchParams = {
      page,
      pageSize,
    };

    const account = get(searchAccountState);

    if (!account) {
      // const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      // params.createdStart = searchTimestamps.createdStart;
      // params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.account = account;
    }

    try {
      const resp = await fetchCaptchas(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const captchasQuery = selector({
  key: "captchas",
  get: ({ get }) => {
    return get(captchasRespQuery)?.data ?? [];
  },
});

export const captchasCountQuery = selector({
  key: "captchas/count",
  get: ({ get }) => {
    return get(captchasRespQuery)?.page?.total ?? 0;
  },
});
