import { Input, Stack, Text } from "@chakra-ui/react";
import { string } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const searchUIDState = atom({
  key: "uid",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

type UIDSearchBarProps = {
  onChange?: () => void;
};

export function UIDSearchBar(props: UIDSearchBarProps) {
  const [uid, setUID] = useRecoilState(searchUIDState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUID(e.target.value);
    props.onChange && props.onChange();
  };

  return (
    <Stack spacing={0}>
      <Text fontSize="12">Search UID</Text>
      <Input
        size="sm"
        placeholder="UID"
        value={uid}
        onChange={handleChange}
      />
    </Stack>
  );
}
