import { privateAxios } from "services";
import { MonthParams, PageParams, Resp, Timestamp } from "types";

const API_URL_API_SECRET_ADD = "/api/secret/add";
const API_URL_API_SECRET_DELETE = "/api/secret/del";
const API_URL_API_SECRET_EDIT = "/api/secret/edit";
const API_URL_API_SECRET_SEARCH = "/api/secret/search";

export interface SecretToCreate {
  appId: string;
  build: number;
  secret: string;
}

export interface SecretToUpdate extends SecretToCreate {
  id: number;
}

export interface Secret extends SecretToUpdate {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface SecretsSearchParams extends PageParams, MonthParams {
  appId?: string;
  build?: number;
}

export type SecretsResp = Resp<Secret[]>;

export const createSecret = (body: SecretToCreate) => {
  return privateAxios.post(API_URL_API_SECRET_ADD, body);
};

export const deleteSecret = (id: number) => {
  return privateAxios.post(API_URL_API_SECRET_DELETE, { id });
};

export const updateSecret = (body: SecretToUpdate) => {
  return privateAxios.post(API_URL_API_SECRET_EDIT, body);
};

export const fetchSecrets = (params: SecretsSearchParams) => {
  return privateAxios.post<SecretsResp>(API_URL_API_SECRET_SEARCH, params);
};
