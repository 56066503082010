import {
  CallStatus,
  CallType,
  MonthParams,
  PageParams,
  Resp,
  Timestamp,
} from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export enum CallSearchType {
  RANDOM_LIST = 1,
  DIRECT_LIST = 2,
  RANDOM_MY = 3,
  DIRECT_MY = 4,
}
export interface CallsParams extends PageParams, MonthParams {
  uid?: number;
  type: CallSearchType;
}

export enum CallRole {
  In,
  Out,
}

export interface Call {
  id: number;
  user: Profile;
  type: CallType; // Random, Direct
  role: CallRole;
  duration: number;
  channelId: string;
  status: CallStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

const API_URL_CALLS_SEARCH = "/video/search";

export type CallsResp = Resp<Call[]>;

export const fetchCalls = (params: CallsParams) => {
  return privateAxios.post<CallsResp>(API_URL_CALLS_SEARCH, params);
};
