import { monthState, searchUIDState } from "components";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { TransactionsParams, fetchTransactions } from "services";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const transactionsPageState = atom({
  key: "transactions/page",
  default: 1,
});

export const transactionsRespQuery = selector({
  key: "transactions/resp",
  get: async ({ get }) => {
    const page = get(transactionsPageState);
    const pageSize = get(pageSizeState);

    const uid = get(searchUIDState);

    const params: TransactionsParams = {
      page,
      pageSize,
    };

    if (!uid) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.uid = +uid;
    }

    try {
      const resp = await fetchTransactions(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const transactionsQuery = selector({
  key: "transactions",
  get: ({ get }) => get(transactionsRespQuery)?.data ?? [],
});
export const transactionsCountQuery = selector({
  key: "transactions/count",
  get: async ({ get }) => get(transactionsRespQuery)?.page?.total ?? 0,
});
