import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { ErrorToast } from "components/ErrorToast";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { RecoilURLSyncJSON } from "recoil-sync";
import { AppRouter } from "router";

const theme = extendTheme(withDefaultColorScheme({ colorScheme: "purple" }));

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      {/* <DevTools /> */}
      <RecoilRoot>
        <RecoilURLSyncJSON location={{ part: "queryParams" }}>
          <RecoilNexus />
          <ErrorToast />
          <AppRouter />
        </RecoilURLSyncJSON>
      </RecoilRoot>
    </ChakraProvider>
  );
};
