import { Badge } from "@chakra-ui/react";
import { CaptchaStatus } from "services";

export function CaptchaStatusBadge({ status }: { status: CaptchaStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case CaptchaStatus.Active:
        return "green";
      case CaptchaStatus.Expired:
        return "gray";
    }
  };

  return <Badge colorScheme={getColorScheme()}>{CaptchaStatus[status]}</Badge>;
}
