import { monthState, searchUIDState } from "components";
import { RecoilState, atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  RelationshipSearchType,
  RelationshipsParams,
  fetchRelationships,
} from "services";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

type relationshipsRespQueryProps = {
  type: RelationshipSearchType;
  uid?: number | string;
  pageState: RecoilState<number>;
};

export const relationshipsRespQuery = selectorFamily({
  key: "relationships/resp",
  get:
    (props: relationshipsRespQueryProps) =>
    async ({ get }) => {
      const page = get(props.pageState);
      const pageSize = get(pageSizeState);

      const { type, uid } = props;

      const params: RelationshipsParams = {
        page,
        pageSize,
        type,
      };

      if (!uid) {
        const searchTimestamps = getSearchTimestampsFrom(get(monthState));
        params.createdStart = searchTimestamps.createdStart;
        params.createdEnd = searchTimestamps.createdEnd;
      } else {
        params.uid = +uid;
      }

      try {
        const resp = await fetchRelationships(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const likesPageState = atom({
  key: "likes/page",
  default: 1,
});

export const likeRelationshipsQuery = selector({
  key: "relationship/likes",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: likesPageState,
          type: RelationshipSearchType.LIKE_LIST,
          uid,
        })
      )?.data ?? []
    );
  },
});

export const likeRelationshipsCountQuery = selector({
  key: "relationship/likes/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: likesPageState,
          type: RelationshipSearchType.LIKE_LIST,
          uid,
        })
      )?.page?.total ?? 0
    );
  },
});

export const followsPageState = atom({
  key: "follows/page",
  default: 1,
});

export const followRelationshipsQuery = selector({
  key: "relationship/follows",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: followsPageState,
          type: RelationshipSearchType.FOLLOW_LIST,
          uid,
        })
      )?.data ?? []
    );
  },
});

export const followRelationshipsCountQuery = selector({
  key: "relationship/follows/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: followsPageState,
          type: RelationshipSearchType.FOLLOW_LIST,
          uid,
        })
      )?.page?.total ?? 0
    );
  },
});

export const blocksPageState = atom({
  key: "blocks/page",
  default: 1,
});

export const blockRelationshipsQuery = selector({
  key: "relationship/blocks",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: blocksPageState,
          type: RelationshipSearchType.BLOCK_LIST,
          uid,
        })
      )?.data ?? []
    );
  },
});

export const blockRelationshipsCountQuery = selector({
  key: "relationship/blocks/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        relationshipsRespQuery({
          pageState: blocksPageState,
          type: RelationshipSearchType.BLOCK_LIST,
          uid,
        })
      )?.page?.total ?? 0
    );
  },
});
