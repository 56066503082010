import {
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Spacer,
  StackProps,
  Text,
} from "@chakra-ui/react";

import { Suspense } from "react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { maxPageState } from "state";

interface PaginationProps extends StackProps {
  state: RecoilState<number>;
  totalState: RecoilValueReadOnly<number>;
}

export function Pagination({
  state,
  totalState,
  ...stackProps
}: PaginationProps) {
  return (
    <HStack {...stackProps}>
      <Suspense fallback={<></>}>
        <PaginationContent
          totalState={totalState}
          state={state}
        />
      </Suspense>
      <Spacer />
    </HStack>
  );
}

export function PaginationContent({ state, totalState }: PaginationProps) {
  const total = useRecoilValue(totalState);
  const maxPage = useRecoilValue(maxPageState(total));
  const setCurrentPage = useSetRecoilState(state);
  const handleClickPre = () => {
    setCurrentPage((prev) => {
      return Math.max(prev - 1, 1);
    });
  };

  const handleClickNext = () => {
    setCurrentPage((prev) => {
      return Math.min(prev + 1, maxPage);
    });
  };

  if (total <= 0) return <></>;

  return (
    <>
      <Button onClick={handleClickPre}>Pre</Button>
      <PageInput
        state={state}
        maxPage={maxPage}
      />
      <Text>/ {maxPage}</Text>
      <Button onClick={handleClickNext}>Next</Button>
    </>
  );
}

type PageInputProps = {
  state: RecoilState<number>;
  maxPage: number;
};

function PageInput({ state, maxPage }: PageInputProps) {
  const [currentPage, setCurrentPage] = useRecoilState(state);

  const handlePageSubmit = (value: string) => {
    const page = +value;

    if (page < 1 || isNaN(page)) {
      setCurrentPage(1);
    } else {
      const _page = Math.min(page, maxPage);
      setCurrentPage(_page);
    }
  };

  return (
    <Editable
      value={currentPage.toString()}
      onChange={handlePageSubmit}>
      <EditablePreview />
      <EditableInput w={"32px"} />
    </Editable>
  );
}
