import CryptoJS from "crypto-js";
import { ManagerFormValues } from "pages";
import { Manager, PageParams, Resp } from "types";
import { privateAxios } from "./axios";

export interface ManagersParams extends PageParams {
  account?: string;
}

export type ManagerResp = Resp<Manager>;

const API_URL_MANAGERS_SEARCH = "/manager/search";
const API_URL_MANAGERS_ADD = "/manager/add";
const API_URL_MANAGERS_EDIT = "/manager/edit";
const API_URL_MANAGERS_DELETE = "/manager/del";

export type ManagersResp = Resp<Manager[]>;

export const fetchManagers = (params: ManagersParams) => {
  return privateAxios.post<ManagersResp>(API_URL_MANAGERS_SEARCH, params);
};

export type ManagerBody = {
  id?: number;
  password?: string;
  username: string;
  phone: string;
  email: string;
  role: number;
};

export const addManager = (body: ManagerFormValues) => {
  const { password } = body;
  const pwdMD5 = CryptoJS.MD5(password).toString();

  return privateAxios.post(API_URL_MANAGERS_ADD, { ...body, password: pwdMD5 });
};

export const editManager = (id: number, body: ManagerFormValues) => {
  const { password } = body;
  const pwdMD5 = !password ? undefined : CryptoJS.MD5(password).toString();

  return privateAxios.post(API_URL_MANAGERS_EDIT, {
    ...body,
    id,
    password: pwdMD5,
  });
};

export const deleteManager = (id: number) => {
  return privateAxios.post(API_URL_MANAGERS_DELETE, { id })
}