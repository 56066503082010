import { Stack, Text, Input } from "@chakra-ui/react";
import { string } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
export const searchOriginalID = atom({
  key: "searchOriginalID",
  default: "",
  effects: [syncEffect({ refine: string() })],
});
type searchOriginal = {
  onChange?: () => void;
};
export function OriginalID(props: searchOriginal) {
  const [originalID, setOriginalID] = useRecoilState(searchOriginalID);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOriginalID(e.target.value);
    props.onChange && props.onChange();
  };
  return (
    <Stack spacing={0}>
      <Text fontSize="12">Original ID</Text>
      <Input
        size="sm"
        placeholder="Original ID"
        value={originalID}
        onChange={handleChange}
      />
    </Stack>
  )
}