import { number } from "@recoiljs/refine";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { subStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";

export const subStatuses = [
  subStatus.All,
  subStatus.AppStore,
  subStatus.GooglePlay,
];

export const subStatusState = atom({
  key: "subs_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function SubStatusFilter() {
  return (
    <FilterSelector
      label="Source"
      statusState={subStatusState}
      options={subStatuses.map((status, index) => {
        const option: SelectFilterOption = {
          label: subStatus[status],
          value: index,
        };
        return option;
      })}
    />
  );
}
