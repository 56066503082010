import dayjs, { Dayjs } from "dayjs";
import { PROJECT_BIRTHDAY } from "utils/constants";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";
import { monthState } from "./filter.state";

export function MonthFilter() {
  return (
    <FilterSelector
      label="Month"
      statusState={monthState}
      options={generateMonths(dayjs(PROJECT_BIRTHDAY, "YYYYMM"))}
    />
  );
}

function generateMonths(startDate: Dayjs) {
  const now = dayjs();

  let options: SelectFilterOption[] = [];
  let tmp = now.clone();

  while (startDate.isBefore(tmp)) {
    const option: SelectFilterOption = {
      label: tmp.format("MMM YYYY"),
      value: +tmp.format("YYYYMM"),
    };
    options.push(option);
    tmp = tmp.subtract(1, "month");
  }

  return options;
}
