import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchReportedProfiles } from "services";
import { fetchReportedStories } from "services/apis/reported-story";
import { getErrorMessage } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";
import dayjs from "dayjs";

export const reportedProfilesPageState = atom({
  key: "reportedProfiles/page",
  default: 1,
});

export const reportedProfilesRequestIdState = atom({
  key: "reportedProfiles/page/requestId",
  default: dayjs().valueOf()
})

const reportedProfilesRespQuery = selector({
  key: "reportedProfiles/resp",
  get: async ({ get }) => {
    const page = get(reportedProfilesPageState);
    const pageSize = get(pageSizeState);

    try {
      const resp = await fetchReportedProfiles({ page, pageSize });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedProfilesQuery = selector({
  key: "reportedProfiles",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.data ?? [];
  },
});

export const reportedProfilesCountQuery = selector({
  key: "reportedProfiles/count",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.page?.total ?? 0;
  },
});

export const reportedStoriesPageState = atom({
  key: "reportedStories/page",
  default: 1,
});

export const reportedStoriesRequestId = atom({
  key: "reportedStories/requestId",
  default: dayjs().valueOf(),
})

const reportedStoriesRespQuery = selector({
  key: "reportedStories/resp",
  get: async ({ get }) => {
    get(reportedStoriesRequestId)
    const page = get(reportedStoriesPageState);
    const pageSize = get(pageSizeState);

    try {
      const resp = await fetchReportedStories({ page, pageSize });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedStoriesState = selector({
  key: "reportedStories",
  get: ({ get }) => {
    return get(reportedStoriesRespQuery)?.data ?? [];
  },
});

export const reportedStoriesCountState = selector({
  key: "reportedStories/count",
  get: ({ get }) => {
    return get(reportedStoriesRespQuery)?.page?.total ?? 0;
  },
});
