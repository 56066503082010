import { Grid, GridItem, StackProps } from "@chakra-ui/react";
import React from "react";

export const ZStack = (props: StackProps) => {
  const { children, ...restProps } = props;

  return (
    <Grid justifyItems="center" alignItems="center" {...restProps}>
      {React.Children.map(children, (children, index) => (
        <GridItem w="100%" h="100%" gridArea="1/1/1/1" zIndex={index}>
          {children}
        </GridItem>
      ))}
    </Grid>
  );
};
