import { BioStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";
import { bioStatusOptionState } from "./filter.state";

export const bioStatuses = [
  BioStatus.Pending,
  BioStatus.Approved,
  BioStatus.Rejected,
  BioStatus.Deleted,
];

export function BioStatusFilter() {
  return (
    <FilterSelector
      label="Status"
      statusState={bioStatusOptionState}
      options={bioStatuses.map((status, index) => {
        const option: SelectFilterOption = {
          label: BioStatus[status],
          value: index,
        };
        return option;
      })}
    />
  );
}
