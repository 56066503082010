import {
  mediaStatusOptionState,
  mediaStatuses,
  monthState,
} from "components/filters";
import dayjs from "dayjs";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchPhotos } from "services/apis/media";
import { errorState, pageSizeState } from "state";
import { MediaStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const photosRequestIdState = atom({
  key: "photos/requestId",
  default: dayjs().valueOf(),
});

export const photosPageState = atom({
  key: "photos/page",
  default: 1,
});

export const photosRespQuery = selector({
  key: "photos/resp",
  get: async ({ get }) => {
    get(photosRequestIdState);

    const params: SearchParams<MediaStatus[]> = {
      page: get(photosPageState),
      pageSize: get(pageSizeState),
    };

    const mediaStatusValue = get(mediaStatusOptionState);

    params.status = [mediaStatuses[mediaStatusValue]];

    if (mediaStatusValue !== 0) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    }

    try {
      return await fetchPhotos(params);
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const photosQuery = selector({
  key: "photos",
  get: ({ get }) => {
    return get(photosRespQuery)?.data.data ?? [];
  },
});

export const photosCountQuery = selector({
  key: "photos/count",
  get: ({ get }) => {
    return get(photosRespQuery)?.data.page?.total ?? 0;
  },
});

export const rejectedPhotosIdsState = atom<number[]>({
  key: "photos/rejected",
  default: [],
});

export const isPhotoSelectedState = selectorFamily({
  key: "isPhotoSelected",
  get:
    (id: number) =>
    ({ get }) => {
      return get(rejectedPhotosIdsState).includes(id);
    },
});

export const photosToSubmitQuery = selector({
  key: "photos/submit",
  get: ({ get }) => {
    const photos = get(photosQuery);
    const rejectedIds = get(rejectedPhotosIdsState);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    photos.forEach((photo) => {
      if (rejectedIds.includes(photo.id)) {
        reviewBody.refuseIds.push(photo.id);
      } else if (photo.status === MediaStatus.Pending) {
        reviewBody.adoptIds.push(photo.id);
      }
    });

    return reviewBody;
  },
});
