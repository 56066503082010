import {
  Box,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  UIDSearchBar,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import { Suspense } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  transactionsCountQuery,
  transactionsPageState,
  transactionsQuery,
} from "state";
import { TransactionAction, TransactionType } from "types";
import { timestampMsToDateStr } from "utils";

export function Transactions() {
  const resetPage = useResetRecoilState(transactionsPageState);
  return (
    <Stack w="100%">
      <MainHeader totalState={transactionsCountQuery}>
        <UIDSearchBar onChange={resetPage} />
        <MonthFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <TransactionsTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          state={transactionsPageState}
          totalState={transactionsCountQuery}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function TransactionsTable() {
  const transactions = useRecoilValue(transactionsQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>User</Th>
            <Th>Type</Th>
            <Th>Value</Th>
            <Th>Balance</Th>
            <Th>Receipt Id / Call Id / Gift ID</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.map((transaction) => (
            <Tr key={transaction.id}>
              <Td>{transaction.id}</Td>
              <Td>
                <ProfileBasicInfo profile={transaction.user} />
              </Td>
              <Td>{TransactionType[transaction.type]}</Td>

              <Td>
                {TransactionAction[transaction.action]}: {transaction.amount}
              </Td>
              <Td>{transaction.balance}</Td>
              <Td>{transaction.scene}</Td>
              <Td>{timestampMsToDateStr(transaction.createdAt)}</Td>
              <Td>{timestampMsToDateStr(transaction.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
