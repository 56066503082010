import { number } from "@recoiljs/refine";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { ProfileStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";

export const profileStatuses = [
  ProfileStatus.All,
  ProfileStatus.Pending,
  ProfileStatus.Approved,
  ProfileStatus.Rejected,
  ProfileStatus.Deleted,
];

export const profileStatusState = atom({
  key: "profile_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function ProfileStatusFilter() {
  return (
    <FilterSelector
      label="Status"
      statusState={profileStatusState}
      options={profileStatuses.map((status, index) => {
        const option: SelectFilterOption = {
          label: ProfileStatus[status],
          value: index,
        };
        return option;
      })}
    />
  );
}
