import { MonthParams, PageParams, Resp, Timestamp } from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export enum GiftSearchType {
  LIST = 1,
  SENT,
  RECEIVED,
}

export interface GiftRecordsParams extends PageParams, MonthParams {
  uid?: number;
  type: GiftSearchType;
}

export type Gift = {
  id: number;
  alias: string;
  name: string;
  cover: string; // url
  coins: number;
};

export interface GiftRecord {
  id: number;
  initiator: Profile;
  recipient: Profile;
  gift: Gift;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

const API_URL_GIFTS_SEARCH = "/gift/search";

export type GiftRecordsResp = Resp<GiftRecord[]>;

export const fetchGiftRecords = (params: GiftRecordsParams) => {
  return privateAxios.post<GiftRecordsResp>(API_URL_GIFTS_SEARCH, params);
};
