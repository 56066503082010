import { Input, Stack, Text } from "@chakra-ui/react";
import { string } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const searchUUIDState = atom({
  key: "uuid",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

type UUIDSearchBarProps = {
  onChange?: () => void;
};

export function UUIDSearchBar(props: UUIDSearchBarProps) {
  const [uuid, setUUID] = useRecoilState(searchUUIDState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUUID(e.target.value);
    props.onChange && props.onChange();
  };

  return (
    <Stack spacing={0}>
      <Text fontSize="12">Search UUID</Text>
      <Input
        size="sm"
        placeholder="UUID"
        value={uuid}
        onChange={handleChange}
      />
    </Stack>
  );
}
