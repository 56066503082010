import { Resp, Timestamp } from "types";

export interface Avatar {
  id: number;
  url: string;
  coverImageUrl: string;
  title: string;
  description: string;
  likes: string;
  status: MediaStatus;
  createdAt: Timestamp;
}

export interface Media {
  id: number;
  uid: number;
  url: string;
  status: MediaStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export enum MediaStatus {
  Deleted = -1,
  Pending,
  Approved,
  Rejected,
}

export type Photo = Media;

export interface Story extends Media {
  description: string;
  likes: number;
}

export type PhotosResp = Resp<Photo[]>;
export type StoriesResp = Resp<Story[]>;
