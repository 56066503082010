import { Box, Spacer, Spinner, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MediaStatusFilter,
  MonthFilter,
  Pagination,
  PhotoView,
  SubmitButton,
  mediaStatusOptionState,
} from "components";
import dayjs from "dayjs";
import { Suspense } from "react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { reviewPhotos } from "services";
import {
  photosCountQuery,
  photosPageState,
  photosQuery,
  photosRequestIdState,
  photosToSubmitQuery,
  rejectedPhotosIdsState,
} from "state";
import { Photo } from "types";

export function Photos() {
  const mediaStatusOption = useRecoilValue(mediaStatusOptionState);

  const updateRequestId = useSetRecoilState(photosRequestIdState);

  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={photosCountQuery}>
        {mediaStatusOption !== 0 && <MonthFilter />}
        <MediaStatusFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner size="sm" />}>
          <PhotosWarp
            state={photosQuery}
            rejectedPhotoIdsState={rejectedPhotosIdsState}
          />
        </Suspense>
      </Box>

      <Spacer />

      <MainFooter>
        <Pagination
          state={photosPageState}
          totalState={photosCountQuery}
        />
        <Spacer />
        {[0, 1].includes(mediaStatusOption) && (
          <SubmitButton
            state={photosToSubmitQuery}
            requestIdState={photosRequestIdState}
            rejectedIdsState={rejectedPhotosIdsState}
            api={reviewPhotos}
            onFinished={() => updateRequestId(dayjs().valueOf())}
          />
        )}
      </MainFooter>
    </Stack>
  );
}

export function PhotosWarp({
  state,
  rejectedPhotoIdsState,
}: {
  state: RecoilValueReadOnly<Photo[]>;
  rejectedPhotoIdsState: RecoilState<number[]>;
}) {
  const photos = useRecoilValue(state);

  return (
    <Wrap>
      {photos.map((photo) => (
        <WrapItem key={photo.id}>
          <PhotoView
            w="188px"
            photo={photo}
            rejectedPhotoIdsState={rejectedPhotoIdsState}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}
