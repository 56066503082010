import { Timestamp } from "./common";

export enum ManagerStatus {
  Deleted = -1,
  Pending,
  Activated,
  Disabled,
}

export interface Manager extends Token {
  id: number;
  username: string;
  email: string;
  phone: string;
  avatarUrl: string;
  role: number;
  status: ManagerStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface Token {
  token: string;
  refToken: string;
  rememberMe: boolean;
  // tokenExp: Timestamp;
  // refTokenExp: Timestamp;
}
