import { Select, Stack, Text } from "@chakra-ui/react";
import { RecoilState, useRecoilState } from "recoil";

export interface SelectFilterOption {
  label: string;
  value: number;
}

export interface StatusFilterProps {
  label?: string;
  placeholder?: string;
  statusState: RecoilState<number>;
  options: SelectFilterOption[];
}

export function FilterSelector(props: StatusFilterProps) {
  const [status, setStatus] = useRecoilState(props.statusState);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(+e.target.value);
  };

  return (
    <Stack spacing={0}>
      <Text fontSize="12">{props.label}</Text>
      <Select
        size="sm"
        placeholder={props.placeholder}
        value={status}
        onChange={handleChange}>
        {props.options.map((opt) => (
          <option
            key={opt.label}
            value={opt.value.toString()}>
            {opt.label}
          </option>
        ))}
      </Select>
    </Stack>
  );
}
