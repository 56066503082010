import {
  DeviceStatus,
  MonthParams,
  PageParams,
  Platform,
  Resp,
  ReviewBody,
  Timestamp,
} from "types";
import { privateAxios } from "./axios";

export interface DevicesParams extends PageParams, MonthParams {
  imei?: string;
  status?: DeviceStatus[];
}

export interface Device {
  id: number;
  alias: string;
  imei: string;
  platform: Platform;
  hardware: string;
  os: string;
  packageBuild: number;
  packageBundleId: string;
  packageVersion: string;
  status: DeviceStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

const API_URL_DEVICES_SEARCH = "/device/search";
const API_URL_DEVICES_REVIEW = "/device/review";

export type DevicesResp = Resp<Device[]>;

export const fetchDevices = (params: DevicesParams) => {
  return privateAxios.post<DevicesResp>(API_URL_DEVICES_SEARCH, params);
};

export const reviewDevice = (body: ReviewBody) => {
  return privateAxios.post(API_URL_DEVICES_REVIEW, body);
};
