import { Gender, PageParams, Resp, Timestamp } from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";
import { ReportStatus } from "./reported-profile";

export interface ProfileDetails extends Profile {
  interests: string[];
  languages: string[];
  loginInfo: {
    source: string;
    imei: string;
    hardware: string;
    ip: string;
    activeTime: Timestamp;
    loginTime: Timestamp;
  };
  matchFilter: {
    genders: Gender[];
    region: string;
  };
  coinQuantity: number;
}

type ProfileDetailsResp = Resp<ProfileDetails>;

const API_URL_PROFILE_DETAILS = "/user/details";

export const fetchProfile = (id: number) => {
  return privateAxios.post<ProfileDetailsResp>(API_URL_PROFILE_DETAILS, {
    id,
  });
};

interface ProfileReportsParams extends PageParams {
  uid?: number;
  type: number;
}

const API_URL_PROFILE_REPORTS = "/report/search";

type ProfileReport = {
  reportedUser: Profile;
  user: Profile;
  reason: string;
  status: ReportStatus;
  createdAt: Timestamp;
};

type ProfileReportResp = Resp<ProfileReport[]>;

export const fetchProfileReports = (params: ProfileReportsParams) => {
  return privateAxios.post<ProfileReportResp>(API_URL_PROFILE_REPORTS, params);
};

const API_URL_PROFILE_COINS = "/user/edit/coin/quantity";

type EditCoinsBody = {
  id: number;
  type: number;
  quantity: number;
};

export const editProfileCoins = (body: EditCoinsBody) => {
  return privateAxios.post(API_URL_PROFILE_COINS, body);
};

//查看用户订阅信息
const API_URL_PROFILE_SUBSCRIPTION = "iap/user/subscription"
export const checkUserSub = (uid: number) => {
  return privateAxios.post(API_URL_PROFILE_SUBSCRIPTION, {uid})
}

//查看用户订单记录
const API_URL_PROFILE_ORDER = "iap/search"
export const checkUserOrder = (uid:number,page:number,pageSize:number) =>{
  return privateAxios.post(API_URL_PROFILE_ORDER,{uid,page,pageSize})
}


