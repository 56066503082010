import axios, { AxiosResponse } from "axios";

import { getRecoil, setRecoil } from "recoil-nexus";
import { managerState } from "state";
import { Page } from "types";
import { StatusCode } from "types/status-code";
import { getIdToken } from "utils";
import { refreshToken } from "./auth";

export const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 10000,
});

export const privateAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 10000,
});

privateAxios.interceptors.request.use(
  async (config) => {
    const idToken = await getIdToken();

    if (idToken) {
      config.headers.set("Token", idToken);
    }

    return config;
  },
  (error) => {
    throw error;
  }
);

publicAxios.interceptors.response.use(
  async (response) => {
    return axiosResponseInterceptor(response);
  },
  (error) => {
    throw error;
  }
);

privateAxios.interceptors.response.use(
  async (response) => {
    return axiosResponseInterceptor(response);
  },
  (error) => {
    throw error;
  }
);

async function axiosResponseInterceptor(response: AxiosResponse<any, any>) {
  // catch global error code
  const {
    code,
    data,
    error,
    page,
  }: { code: StatusCode; data: any; error: string; page: Page } = response.data;

  switch (code) {
    case StatusCode.Ok:
      return { ...response, data: { data, page } };
    case StatusCode.NotLoggedIn:
      setRecoil(managerState, null);
      throw new Error(error);
    case StatusCode.TokenRevoked:
      setRecoil(managerState, null);
      throw new Error(error);
    case StatusCode.TokenExpired:
      const manager = getRecoil(managerState);

      if (manager && manager.rememberMe) {
        const resp = await refreshToken(manager.refToken);

        setRecoil(managerState, { ...resp.data.data, rememberMe: true });

        response.config.headers.set("token", resp.data.data.token);

        return privateAxios(response.config);
      }

      setRecoil(managerState, null);
      throw new Error(error);
    case StatusCode.RefreshTokenExpired:
      setRecoil(managerState, null);
      throw new Error(error);
    default:
      throw new Error(error);
  }
}
