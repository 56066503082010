import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { MainFooter, MainHeader, Pagination } from "components";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { Suspense } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FiChevronDown, FiDelete } from "react-icons/fi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { Secret, createSecret, deleteSecret, updateSecret } from "services";
import { errorState } from "state";
import {
  secretsCountQuery,
  secretsPageState,
  secretsQuery,
  secretsRequestIdState,
} from "state/secrets";
import { getErrorMessage, timestampMsToDateStr } from "utils";

export function Secrets() {
  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={secretsCountQuery}>
        <AddSecretButton />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <SecretsTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          totalState={secretsCountQuery}
          state={secretsPageState}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function SecretsTable() {
  const secrets = useRecoilValue(secretsQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>App ID</Th>
            <Th>Build</Th>
            <Th>Secret</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {secrets.map((secret) => (
            <Tr key={secret.id}>
              <Td>{secret.id}</Td>
              <Td>{secret.appId}</Td>
              <Td>{secret.build}</Td>
              <Td>{secret.secret}</Td>
              <Td>{timestampMsToDateStr(secret.createdAt)}</Td>
              <Td>{timestampMsToDateStr(secret.updatedAt)}</Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<FiChevronDown />}
                    colorScheme="gray">
                    Actions
                  </MenuButton>
                  <MenuList>
                    <EditSecretMenuItem secret={secret} />

                    <MenuDivider />
                    <DeleteSecretMenuItem id={secret.id} />
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function AddSecretButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(secretsRequestIdState);

  const formik = useFormik({
    initialValues: {
      appId: "",
      build: 0,
      secret: "",
    },
    onSubmit: async (values) => {
      try {
        await createSecret(values);
        updateRequestId(dayjs().valueOf());
        formik.resetForm();
        onClose();
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <Button onClick={onOpen}>Add Secret</Button>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Create New Secret</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>App ID</FormLabel>
                  <Input
                    ref={initialRef}
                    name="appId"
                    type="text"
                    value={formik.values.appId}
                    onChange={formik.handleChange}
                    placeholder="App ID"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Build</FormLabel>
                  <Input
                    name="build"
                    type="number"
                    value={formik.values.build}
                    onChange={formik.handleChange}
                    placeholder="Build"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Secret</FormLabel>
                  <Input
                    name="secret"
                    type="text"
                    value={formik.values.secret}
                    onChange={formik.handleChange}
                    placeholder="Secret"
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formik.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

function DeleteSecretMenuItem({ id }: { id: number }) {
  const updateRequestId = useSetRecoilState(secretsRequestIdState);

  const handleDelete = async () => {
    try {
      await deleteSecret(id);
      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  };

  return (
    <MenuItem
      icon={<FiDelete />}
      color="red"
      onClick={handleDelete}>
      Delete
    </MenuItem>
  );
}

function EditSecretMenuItem({ secret }: { secret: Secret }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(secretsRequestIdState);

  const formik = useFormik({
    initialValues: {
      appId: secret.appId,
      build: secret.build,
      secret: secret.secret,
    },
    onSubmit: async (values) => {
      try {
        await updateSecret({ id: secret.id, ...values });
        updateRequestId(dayjs().valueOf());
        formik.resetForm();
        onClose();
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <MenuItem
        icon={<AiOutlineEdit />}
        onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Edit Secret</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>App ID</FormLabel>
                  <Input
                    ref={initialRef}
                    name="appId"
                    type="text"
                    value={formik.values.appId}
                    onChange={formik.handleChange}
                    placeholder="App ID"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Build</FormLabel>
                  <Input
                    name="build"
                    type="number"
                    value={formik.values.build}
                    onChange={formik.handleChange}
                    placeholder="Build"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Secret</FormLabel>
                  <Input
                    name="secret"
                    type="text"
                    value={formik.values.secret}
                    onChange={formik.handleChange}
                    placeholder="Secret"
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formik.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
