import {
  Box,
  HStack,
  Image,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  UIDSearchBar,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import { Suspense } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { giftRecordsCountQuery, giftRecordsQuery, giftsPageState } from "state";
import { timestampMsToDateStr } from "utils";

export function Gifts() {
  const resetPage = useResetRecoilState(giftsPageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={giftRecordsCountQuery}>
        <UIDSearchBar onChange={resetPage} />
        <MonthFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <GiftsTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          state={giftsPageState}
          totalState={giftRecordsCountQuery}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function GiftsTable() {
  const gifts = useRecoilValue(giftRecordsQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>From</Th>
            <Th>Gift</Th>
            <Th>To</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {gifts.map((gift) => (
            <Tr key={gift.id}>
              <Td>{gift.id}</Td>
              <Td>
                <ProfileBasicInfo profile={gift.initiator} />
              </Td>
              <Td>
                <HStack>
                  <Image
                    src={gift.gift.cover}
                    w="52px"
                    h="52px"
                  />
                  <Text>{gift.gift.name}</Text>
                </HStack>
              </Td>
              <Td>
                <ProfileBasicInfo profile={gift.recipient} />
              </Td>
              <Td>{timestampMsToDateStr(gift.createdAt)}</Td>
              <Td>{timestampMsToDateStr(gift.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
