import { searchUIDState } from "components";
import {
  bioStatusOptionState,
  bioStatuses,
  monthState,
} from "components/filters";
import dayjs from "dayjs";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { Bio, fetchBios } from "services";
import { errorState, pageSizeState } from "state";
import { BioStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const biosRequestIdState = atom({
  key: "bios/requestId",
  default: dayjs().valueOf(),
});

export const biosPageState = atom({
  key: "bios/page",
  default: 1,
});

export const biosRespQuery = selectorFamily({
  key: "bios/resp",
  get:
    (uid?: number) =>
    async ({ get }) => {
      get(biosRequestIdState);

      const params: SearchParams<BioStatus[]> = {
        page: get(biosPageState),
        pageSize: get(pageSizeState),
      };

      if (!uid) {
        const statusValue = get(bioStatusOptionState);
        params.status = [bioStatuses[statusValue]];

        if (statusValue !== 0) {
          const searchTimestamps = getSearchTimestampsFrom(get(monthState));
          params.createdStart = searchTimestamps.createdStart;
          params.createdEnd = searchTimestamps.createdEnd;
        }
      } else {
        params.uid = uid;
        params.status = [
          BioStatus.Approved,
          BioStatus.Pending,
          BioStatus.Rejected,
        ];
      }

      try {
        const resp = await fetchBios(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const biosQuery = selector({
  key: "bios",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(biosRespQuery(+uid))?.data ?? [];
  },
});

export const biosCountQuery = selector({
  key: "bios/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(biosRespQuery(+uid))?.page?.total ?? 0;
  },
});

export const rejectedBiosIdsState = atom<number[]>({
  key: "bios/rejected",
  default: [],
});

export const isBioSelectedState = selectorFamily({
  key: "bio/isSelected",
  get:
    (id: number) =>
    ({ get }) => {
      return get(rejectedBiosIdsState).includes(id);
    },
});

export const biosToSubmitTempleQuery = selectorFamily({
  key: "bios/submit/temple",
  get:
    (uid?: number) =>
    ({ get }) => {
      let bios: Bio[] = [];
      if (uid) {
        bios = get(profileBiosQuery(uid));
      } else {
        bios = get(biosQuery);
      }

      const rejectedIds = get(rejectedBiosIdsState);

      const reviewBody: ReviewBody = {
        adoptIds: [],
        refuseIds: [],
      };

      bios.forEach((item) => {
        if (rejectedIds.includes(item.id)) {
          reviewBody.refuseIds.push(item.id);
        } else if (item.status === BioStatus.Pending) {
          reviewBody.adoptIds.push(item.id);
        }
      });

      return reviewBody;
    },
});

export const biosToSubmitQuery = selector({
  key: "bios/submit",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(biosToSubmitTempleQuery(+uid));
  },
});

// profile bio
export const profileBiosQuery = selectorFamily({
  key: "profile/bios",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(biosRespQuery(uid))?.data ?? [];
    },
});

export const profileBiosToSubmitQuery = selectorFamily({
  key: "profile/bios/submit",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(biosToSubmitTempleQuery(uid));
    },
});
