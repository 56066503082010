import { MediaStatus } from "types";

export const getColorSchemeFrom = (status: MediaStatus) => {
  switch (status) {
    case MediaStatus.Pending:
      return undefined;
    case MediaStatus.Approved:
      return "green.100";
    case MediaStatus.Rejected:
      return "red.100";
    case MediaStatus.Deleted:
      return "gray.100";
  }
};
