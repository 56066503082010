import { HStack, StackProps } from "@chakra-ui/react";

export function MainFooter({ children, ...restProps }: StackProps) {
  return (
    <HStack
      position={"sticky"}
      left={0}
      bottom={0}
      h={"54px"}
      pr={2}
      p={2}
      justifyContent={"center"}
      bg={"whiteAlpha.800"}
      {...restProps}>
      {children}
    </HStack>
  );
}
