import { privateAxios } from "./axios";
import { MonthParams, PageParams, subStatus } from "types";


export interface SubsParams extends PageParams, MonthParams {
    id?: number;
    uid?: string;
    originalTransactionId?:string;
    transactionId?:string;
    source?: subStatus;
  }
  export interface NotificationsParams extends PageParams, MonthParams {
    uid?: string;
    originalTransactionId?:string;
    transactionId?:string;
    source?: subStatus;
  }
export interface TransfersParams extends PageParams, MonthParams {
  id?: number;
  uid?: string;
  originalTransactionId?:string;
  source?: subStatus;
}
const API_URL_SUB_ORDER = "iap/search"
export const checkAllUserOrder = (params: SubsParams) => {
    return privateAxios.post(API_URL_SUB_ORDER,params)
}

const API_URL_SUB_TRANSFER = "iap/subscription/transfer/search"
export const checkAllTransfer = (params: TransfersParams) =>{
  return privateAxios.post(API_URL_SUB_TRANSFER,params)
}
const API_URL_SUB_NOTIFICATION = "iap/subscription/notification/search"
export const checkAllNotification = (params: NotificationsParams) =>{
  return privateAxios.post(API_URL_SUB_NOTIFICATION,params)
}