import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { login } from "services";
import { managerState } from "state";
import { errorState } from "state/error";
import { getErrorMessage, ROUTE_URI_PHOTOS } from "utils";

type LoginFormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export function Login() {
  const navigation = useNavigate();

  const setManager = useSetRecoilState(managerState);

  const turnstile = useTurnstile();
  const [turnstileToken, setTurnstileToken] = useState("");

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: async (values) => {
      try {
        const resp = await login(values.email, values.password, turnstileToken);

        setManager({ ...resp.data.data, rememberMe: values.rememberMe });
        navigation(ROUTE_URI_PHOTOS);
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }

      turnstile.reset();
    },
  });

  return (
    <Flex h="100vh">
      <Center
        flex="1"
        h="100vh">
        <p>hiyak</p>
      </Center>

      <Box
        w="600px"
        m="4"
        borderRadius={10}
        border="1px"
        borderColor="gray.100">
        <VStack
          h="100%"
          p={16}
          spacing="8">
          <Spacer />
          <VStack spacing="4">
            <Box>LOGO</Box>
            <Heading>Welcome back</Heading>
            <Text>Please enter your details</Text>
          </VStack>

          <form onSubmit={formik.handleSubmit}>
            <VStack
              w="100%"
              spacing={8}>
              <Stack
                spacing="4"
                w="400px">
                <FormControl>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Email"
                    value={formik.values.email}
                    w="100%"
                    onChange={formik.handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </FormControl>

                <HStack w="100%">
                  <Checkbox
                    name="rememberMe"
                    opacity="0.8"
                    isChecked={formik.values.rememberMe}
                    onChange={formik.handleChange}>
                    Remember Me
                  </Checkbox>
                  <Spacer />
                </HStack>

                {process.env.REACT_APP_TURNSTILE_SITE_KEY && (
                  <Stack>
                    <Text opacity={0.6}>Let's know you're human</Text>
                    <Turnstile
                      sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                      onVerify={(token) => {
                        setTurnstileToken(token);
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              <Button
                w="100%"
                borderRadius="full"
                type="submit"
                isLoading={formik.isSubmitting}
                colorScheme="purple"
                size="lg"
                isDisabled={!turnstileToken}>
                Log In
              </Button>
            </VStack>
          </form>

          <Spacer />
        </VStack>
      </Box>
    </Flex>
  );
}
