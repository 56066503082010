import { privateAxios } from "services";
import { MonthParams, PageParams, Resp, Timestamp } from "types";

const API_URL_API_CAPTCHA_ADD = "/captcha/add";
const API_URL_API_CAPTCHA_DELETE = "/captcha/del";
const API_URL_API_CAPTCHA_EDIT = "/captcha/edit";
const API_URL_API_CAPTCHA_SEARCH = "/captcha/search";

export enum CaptchaSource {
  Phone = "PHONE",
  Email = "EMAIL",
}

export enum CaptchaStatus {
  Active,
  Expired,
}

export interface CaptchaToCreate {
  source: CaptchaSource;
  account: string;
  code: string;
  expiredAt: Timestamp;
}

export interface CaptchaToUpdate extends CaptchaToCreate {
  id: number;
}

export interface Captcha extends CaptchaToUpdate {
  status: CaptchaStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface CaptchasSearchParams extends PageParams, MonthParams {
  account?: string;
  source?: CaptchaSource;
}

export type CaptchasResp = Resp<Captcha[]>;

export const createCaptcha = (body: CaptchaToCreate) => {
  return privateAxios.post(API_URL_API_CAPTCHA_ADD, body);
};

export const deleteCaptcha = (id: number) => {
  return privateAxios.post(API_URL_API_CAPTCHA_DELETE, { id });
};

export const updateCaptcha = (body: CaptchaToUpdate) => {
  return privateAxios.post(API_URL_API_CAPTCHA_EDIT, body);
};

export const fetchCaptchas = (params: CaptchasSearchParams) => {
  return privateAxios.post<CaptchasResp>(API_URL_API_CAPTCHA_SEARCH, params);
};
