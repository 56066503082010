import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { MainFooter, MainHeader, Pagination } from "components";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { Suspense, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import { RxTrash } from "react-icons/rx";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { addManager, deleteManager, editManager } from "services";
import {
  errorState,
  managersCountState,
  managersPageState,
  managersQuery,
  managersRequestId,
} from "state";
import { Manager, ManagerStatus } from "types";
import { getErrorMessage, timestampMsToDateStr } from "utils";

export function Managers() {
  return (
    <Stack w="100%">
      <MainHeader totalState={managersCountState}>
        <CreateManagerModal />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <ManagersTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          state={managersPageState}
          totalState={managersCountState}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function ManagersTable() {
  const managers = useRecoilValue(managersQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Avatar</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {managers.map((manager) => (
            <Tr key={manager.id}>
              <Td>{manager.id}</Td>
              <Td>
                <Avatar
                  name={manager.username}
                  src={manager.avatarUrl}
                  size="md"
                />
              </Td>
              <Td>{manager.username}</Td>
              <Td>{manager.email}</Td>
              <Td>{manager.phone}</Td>
              <Td>{ManagerStatus[manager.status]}</Td>
              <Td>{timestampMsToDateStr(manager.createdAt)}</Td>
              <Td>{timestampMsToDateStr(manager.updatedAt)}</Td>
              <Td>
                <HStack>
                  <EditManagerModal manager={manager} />
                  <DeleteManagerDialog id={manager.id} />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type ManagerFormValues = {
  username: string;
  phone: string;
  email: string;
  password: string;
  role: number;
};

function CreateManagerModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const formik = useFormik<ManagerFormValues>({
    initialValues: {
      username: "",
      phone: "",
      email: "",
      password: "",
      role: 1,
    },
    onSubmit: async (values) => {
      console.log(values);

      try {
        await addManager(values);
        onClose();
        formik.resetForm();
        updateRequestId(dayjs().valueOf());
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  const updateRequestId = useSetRecoilState(managersRequestId);

  return (
    <>
      <Button
        colorScheme="purple"
        onClick={onOpen}>
        Add Manager
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Add Manager</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  ref={initialRef}
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl
                isRequired
                mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl
                isRequired
                mt={4}>
                <FormLabel>First Name</FormLabel>
                <Input
                  name="username"
                  type="text"
                  placeholder="First name"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl
                isRequired
                mt={4}>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  type="number"
                  placeholder="Phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
              </FormControl>
              {/* <FormControl
                isRequired
                mt={4}>
                <FormLabel>Role</FormLabel>
                <Input
                  name="role"
                  placeholder="Role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                />
              </FormControl> */}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit">
                Save
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

type EditManagerModalProps = {
  manager: Manager;
};

function EditManagerModal({ manager }: EditManagerModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const formik = useFormik<ManagerFormValues>({
    enableReinitialize: true,
    initialValues: {
      username: manager.username,
      phone: manager.phone,
      email: manager.email,
      password: "",
      role: manager.role,
    },
    onSubmit: async (values) => {
      console.log(values);

      try {
        await editManager(manager.id, values);
        onClose();
        updateRequestId(dayjs().valueOf());
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  const updateRequestId = useSetRecoilState(managersRequestId);

  return (
    <>
      <IconButton
        aria-label={"edit"}
        icon={<FiEdit3 />}
        onClick={onOpen}
      />

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Edit Manager</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  ref={initialRef}
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>First Name</FormLabel>
                <Input
                  name="username"
                  type="text"
                  placeholder="First name"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  type="number"
                  placeholder="Phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
              </FormControl>
              {/* <FormControl mt={4}>
                <FormLabel>Role</FormLabel>
                <Input
                  name="role"
                  placeholder="Role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                />
              </FormControl> */}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit">
                Save
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

function DeleteManagerDialog({ id }: { id: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateRequestId = useSetRecoilState(managersRequestId);

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await deleteManager(id);
      updateRequestId(dayjs().valueOf());
      onClose();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };
  return (
    <>
      <IconButton
        colorScheme="red"
        aria-label={"edit"}
        icon={<RxTrash />}
        onClick={onOpen}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold">
              Delete Manager
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isLoading={isSubmitting}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
