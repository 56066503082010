import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Spacer,
  Text,
} from "@chakra-ui/react";

import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { logout } from "services";
import { managerState } from "state";
import { errorState } from "state/error";
import { getErrorMessage } from "utils";
import { ROUTE_URI_LOGIN } from "utils/constants";

export function UserInfo() {
  const navigate = useNavigate();

  const [user, setUser] = useRecoilState(managerState);
  const setError = useSetRecoilState(errorState);

  if (!user) return <></>;

  const handleSignOut = async () => {
    try {
      await logout();
      setUser(null);
      navigate(ROUTE_URI_LOGIN);
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  return (
    <Box
      bottom="0"
      left="0"
      h="54px"
      px="4"
      py={2}
      borderTop="1px"
      bg="white"
      borderTopColor="gray.100"
      justifyContent="center"
      position={"sticky"}>
      <HStack h="100%">
        <Avatar
          name={user.username}
          src={user.avatarUrl}
          size="sm"
        />
        <Text>{user.username}</Text>
        <Spacer />
        <IconButton
          aria-label="LogOut"
          icon={<FiLogOut />}
          onClick={handleSignOut}
        />
      </HStack>
    </Box>
  );
}
