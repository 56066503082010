import {
  Box,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  UIDSearchBar,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import { Suspense } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { Source } from "services";
import { receiptsCountQuery, receiptsPageState, receiptsQuery } from "state";
import { timestampMsToDateStr } from "utils";

export function Receipts() {
  const resetPage = useResetRecoilState(receiptsPageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={receiptsCountQuery}>
        <UIDSearchBar onChange={resetPage} />
        <MonthFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <ReceiptsTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          state={receiptsPageState}
          totalState={receiptsCountQuery}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function ReceiptsTable() {
  const receipts = useRecoilValue(receiptsQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Source</Th>
            <Th>Buyer</Th>
            <Th>Product ID</Th>
            <Th>Original Transaction ID</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {receipts.map((receipt) => (
            <Tr key={receipt.id}>
              <Td>{receipt.id}</Td>
              <Td>{Source[receipt.source]}</Td>
              <Td>
                <ProfileBasicInfo profile={receipt.user} />
              </Td>
              <Td>{receipt.productId}</Td>
              <Td>{receipt.originalTransactionId}</Td>
              <Td>{timestampMsToDateStr(receipt.createdAt)}</Td>
              <Td>{timestampMsToDateStr(receipt.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
