import {
  Box,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  DeviceSearchBar,
  DeviceStatusFilter,
  DeviceStatusTag,
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
} from "components";
import { Suspense } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { devicesCountQuery, devicesPageState, devicesQuery } from "state";
import { Platform } from "types";
import { timestampMsToDateStr } from "utils";

export function Devices() {
  const resetPage = useResetRecoilState(devicesPageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={devicesCountQuery}>
        <DeviceSearchBar onChange={resetPage} />
        <DeviceStatusFilter />
        <MonthFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <DevicesTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          state={devicesPageState}
          totalState={devicesCountQuery}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function DevicesTable() {
  const devices = useRecoilValue(devicesQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Platform</Th>
            <Th>IMEI</Th>
            <Th>Model</Th>
            <Th>OS</Th>
            <Th>Bundle ID</Th>
            <Th>App</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {devices.map((device) => (
            <Tr key={device.id}>
              <Td>{device.id}</Td>
              <Td>{Platform[device.platform]}</Td>
              <Td>{device.imei}</Td>
              <Td>{device.hardware}</Td>
              <Td>{device.os}</Td>
              <Td>{device.packageBundleId}</Td>
              <Td>{device.packageVersion + "." + device.packageBuild}</Td>
              <Td>
                <DeviceStatusTag status={device.status} />
              </Td>
              <Td>{timestampMsToDateStr(device.createdAt)}</Td>
              <Td>{timestampMsToDateStr(device.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
