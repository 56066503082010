import {
  MonthParams,
  PageParams,
  Resp,
  Timestamp,
  TransactionAction,
  TransactionType,
} from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export interface TransactionsParams extends PageParams, MonthParams {
  uid?: number;
}

export interface Transaction {
  id: number;
  user: Profile;
  type: TransactionType;
  action: TransactionAction;
  amount: number;
  balance: number;
  scene: string; // 发生交易行为的场景\ntype = 0，用户id\ntype = 1，会话记录channel字段\ntype = 2，会话记录channel字段\ntype = 20，订单编号：交易发生时间\ntype = 30，（没有存在的数据）\ntype = 40，gift id\ntype = 50，被邀请用户id\ntype = 51，Rebate obtained by clicking shared
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type TransactionsResp = Resp<Transaction[]>;

const API_URL_TRANSACTIONS_SEARCH = "/transaction/search";

export const fetchTransactions = (params: TransactionsParams) => {
  return privateAxios.post<TransactionsResp>(
    API_URL_TRANSACTIONS_SEARCH,
    params
  );
};
