import { Box, Flex } from "@chakra-ui/react";
import { SideBar } from "components/SideBar";
import { Outlet } from "react-router-dom";

export function MainLayout() {
  return (
    <Flex h={"100vh"}>
      <Box
        w={"240px"}
        overflowY="auto"
        borderRight="1px"
        borderRightColor="gray.100">
        <SideBar />
      </Box>

      <Box
        flex={1}
        overflowY="auto">
        <Outlet />
      </Box>
    </Flex>
  );
}
