import { PageParams, Resp } from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export interface ReportedProfile {
  user: Profile;
  reportUsers: Profile[];
}

export type ReportedProfilesResp = Resp<ReportedProfile[]>;

export enum ReportStatus {
  Pending,
  Reviewed,
}

const API_URL_REPORTED_PROFILES_SEARCH = "/report/user/search";
const API_URL_REPORTED_PROFILES_REVIEW = "/report/user/confirm";

export interface ReportedProfilesParams extends PageParams {}

export const fetchReportedProfiles = (params: ReportedProfilesParams) => {
  return privateAxios.post<ReportedProfilesResp>(
    API_URL_REPORTED_PROFILES_SEARCH,
    params
  );
};

export type ReviewReportedProfilesBody = {
  uids: number[];
};

export const reviewReportedProfiles = (body: ReviewReportedProfilesBody) => {
  return privateAxios.post(API_URL_REPORTED_PROFILES_REVIEW, body);
};
