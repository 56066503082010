import { Avatar, HStack, Stack, Text } from "@chakra-ui/react";
import _ from "lodash";
import { Profile } from "services";
import { Gender } from "types";
import { ProfileId } from "./ProfileId";
import { ProfileStatusBadge } from "./badges/ProfileStatusBadge";

type ProfileBasicInfoProps = {
  profile: Profile;
};

export function ProfileBasicInfo({ profile }: ProfileBasicInfoProps) {
  return (
    <ProfileId uid={profile.id}>
      <HStack key={profile.id}>
        <Avatar
          src={profile.avatar.url}
          name={profile.firstName}
        />
        <Stack spacing={0}>
          <HStack>
            <Text
              fontSize={"18"}
              fontWeight={"medium"}>
              {profile.firstName}
            </Text>
            <ProfileStatusBadge status={profile.status} />
          </HStack>
          <Text fontSize={12}>
            {_.compact([
              profile.age,
              profile.gender && Gender[profile.gender],
              profile.region,
            ]).join(", ")}
          </Text>
        </Stack>
      </HStack>
    </ProfileId>
  );
}
