import { atom } from "recoil";
import { Manager } from "types";
import { localStorageEffect } from "utils";
import { STORAGE_KEY_MANAGER } from "utils/constants";

export const managerState = atom<Manager | null | undefined>({
  key: "manager",
  default: undefined,
  effects: [
    localStorageEffect<Manager | null | undefined>(STORAGE_KEY_MANAGER),
  ],
});
