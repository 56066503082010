import { MonthParams, PageParams, Platform, Resp, Timestamp } from "types";
import { privateAxios } from "./axios";
import { Profile } from "./profiles";

export enum Source {
  Apple = 1,
  Google,
  Console,
}

export enum ProductType {
  Subscription,
  Coins,
}

export interface ReceiptsParams extends PageParams, MonthParams {
  source?: Source;
  originalTransactionId?: string;
  transactionId?: string;
  uid?: number;
}

export interface Receipt {
  id: number;
  user: Profile;
  source: Platform;
  productId: string;
  productType: ProductType;
  originalTransactionId: string;
  transactionId: string;
  offerCode: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  purchaseDate: Timestamp;
  expiresDate: Timestamp;
}

const API_URL_RECEIPTS_SEARCH = "/iap/search";

export type ReceiptsResp = Resp<Receipt[]>;

export const fetchReceipts = (params: ReceiptsParams) => {
  return privateAxios.post<ReceiptsResp>(API_URL_RECEIPTS_SEARCH, params);
};
