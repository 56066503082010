import {
  mediaStatusOptionState,
  mediaStatuses,
  monthState,
  searchUIDState,
} from "components";
import dayjs from "dayjs";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchStories } from "services/apis/media";
import { errorState, pageSizeState } from "state";
import { MediaStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const storiesRequestIdState = atom({
  key: "stories/requestId",
  default: dayjs().valueOf(),
});

export const storiesPageState = atom({
  key: "stories/page",
  default: 1,
});

export const storiesRespQuery = selectorFamily({
  key: "stories/resp",
  get:
    (uid?: number) =>
    async ({ get }) => {
      get(storiesRequestIdState);

      const params: SearchParams<MediaStatus[]> = {
        page: get(storiesPageState),
        pageSize: get(pageSizeState),
      };

      if (!uid) {
        const mediaStatusValue = get(mediaStatusOptionState);

        params.status = [mediaStatuses[mediaStatusValue]];

        if (mediaStatusValue !== 0) {
          const searchTimestamps = getSearchTimestampsFrom(get(monthState));
          params.createdStart = searchTimestamps.createdStart;
          params.createdEnd = searchTimestamps.createdEnd;
        }
      } else {
        params.uid = uid;
        params.status = [
          MediaStatus.Approved,
          MediaStatus.Deleted,
          MediaStatus.Pending,
          MediaStatus.Rejected,
        ];
      }

      try {
        const resp = await fetchStories(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

// list
export const storiesQuery = selector({
  key: "stories",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(storiesRespQuery(+uid))?.data ?? [];
  },
});

export const storiesCountQuery = selector({
  key: "stories/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(storiesRespQuery(+uid))?.page?.total ?? 0;
  },
});

export const rejectedStoriesIdsState = atom<number[]>({
  key: "stories/rejected",
  default: [],
});

export const isStorySelectedState = selectorFamily({
  key: "isStorySelected",
  get:
    (id: number) =>
    ({ get }) => {
      return get(rejectedStoriesIdsState).includes(id);
    },
});

export const storiesToSubmitQuery = selector({
  key: "stories/submit",
  get: ({ get }) => {
    const stories = get(storiesQuery);
    const rejectedIds = get(rejectedStoriesIdsState);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    stories.forEach((story) => {
      if (rejectedIds.includes(story.id)) {
        reviewBody.refuseIds.push(story.id);
      } else if (story.status === MediaStatus.Pending) {
        reviewBody.adoptIds.push(story.id);
      }
    });

    return reviewBody;
  },
});

// profile stories
export const profileStoriesQuery = selectorFamily({
  key: "profile/stories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesRespQuery(uid))?.data ?? [];
    },
});

export const profileStoriesToSubmitQuery = selectorFamily({
  key: "profile/stories/submit",
  get:
    (uid: number) =>
    ({ get }) => {
      const stories = get(profileStoriesQuery(uid));
      const rejectedIds = get(rejectedStoriesIdsState);

      const reviewBody: ReviewBody = {
        adoptIds: [],
        refuseIds: [],
      };

      stories.forEach((story) => {
        if (rejectedIds.includes(story.id)) {
          reviewBody.refuseIds.push(story.id);
        } else if (story.status === MediaStatus.Pending) {
          reviewBody.adoptIds.push(story.id);
        }
      });

      return reviewBody;
    },
});

export const profileStoriesCountQuery = selectorFamily({
  key: "profile/stories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesRespQuery(uid))?.page?.total ?? 0;
    },
});
