import {
  deviceStatusState,
  deviceStatuses,
  monthState,
  searchIMEIState,
} from "components";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { DevicesParams, fetchDevices } from "services";
import { DeviceStatus } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const devicesPageState = atom({
  key: "devices/page",
  default: 1,
});

export const devicesRespQuery = selector({
  key: "devices/resp",
  get: async ({ get }) => {
    const page = get(devicesPageState);
    const pageSize = get(pageSizeState);

    const imei = get(searchIMEIState);

    const params: DevicesParams = {
      page,
      pageSize,
    };

    if (!imei) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;

      const status = deviceStatuses[get(deviceStatusState)];

      if (status === DeviceStatus.All) {
        params.status = [DeviceStatus.Disabled, DeviceStatus.Enabled];
      } else {
        params.status = [status];
      }
    } else {
      params.imei = imei;
    }

    try {
      const resp = await fetchDevices(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const devicesQuery = selector({
  key: "devices",
  get: ({ get }) => get(devicesRespQuery)?.data ?? [],
});

export const devicesCountQuery = selector({
  key: "devices/count",
  get: ({ get }) => get(devicesRespQuery)?.page?.total ?? 0,
});
