import { MediaStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";
import { mediaStatusOptionState } from "./filter.state";

export const mediaStatuses = [
  MediaStatus.Pending,
  MediaStatus.Approved,
  MediaStatus.Rejected,
  MediaStatus.Deleted,
];

export function MediaStatusFilter() {
  return (
    <FilterSelector
      label="Status"
      statusState={mediaStatusOptionState}
      options={mediaStatuses.map((status, index) => {
        const option: SelectFilterOption = {
          label: MediaStatus[status],
          value: index,
        };
        return option;
      })}
    />
  );
}
