import { atom, selector, useSetRecoilState } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { subStatus } from 'types';
import { errorState } from './error';
import { getErrorMessage, getSearchTimestampsFrom } from 'utils';
import { checkAllTransfer, SubsParams } from 'services';
import { pageSizeState } from './pagination';
import { monthState, searchOriginalID, searchUIDState, subStatuses, subStatusState } from 'components';
import React from 'react';


export const transferPageState = atom({
  key: "transfer/page",
  default: 1,
});
export function useSetUID(uid: string) {
  const setUID = useSetRecoilState(searchUIDState);
  React.useEffect(() => {
    setUID(uid);
  }, [uid, setUID]);
}
export const transferRespQuery = selector({
  key: "transfer/resp",
  get: async ({ get }) => {
    const page = get(transferPageState);
    const pageSize = get(pageSizeState);

    let uid = get(searchUIDState);
    const originalTransactionId = get(searchOriginalID);
    const source = subStatuses[get(subStatusState)];

    const params: SubsParams = {
      page,
      pageSize,
    };

    if (!uid && !originalTransactionId) {
      if (source === subStatus.AppStore) {
        params.source = 1;
      } else if (source === subStatus.GooglePlay) {
        params.source = 2;
      }

      const month = get(monthState);
      const searchTimestamps = getSearchTimestampsFrom(month);

      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.id = uid ? +uid : undefined;
      params.originalTransactionId = originalTransactionId
    }
    try {
      const resp = await checkAllTransfer(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});
export const transferQuery = selector({
  key: "transfer",
  get: ({ get }) => {
    return get(transferRespQuery)?.data ?? [];
  },
});
export const transferCountQuery = selector({
  key: "transfer/count",
  get: ({ get }) => {
    return get(transferRespQuery)?.page?.total ?? 0;
  },
});

