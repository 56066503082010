import { monthState, searchUIDState } from "components";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { ReceiptsParams, fetchReceipts } from "services";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const receiptsPageState = atom({
  key: "receipts/page",
  default: 1,
});

export const receiptsRespQuery = selector({
  key: "receipts/resp",
  get: async ({ get }) => {
    const page = get(receiptsPageState);
    const pageSize = get(pageSizeState);

    const uid = get(searchUIDState);

    const params: ReceiptsParams = {
      page,
      pageSize,
    };

    if (!uid) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.uid = +uid;
    }

    try {
      const resp = await fetchReceipts(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const receiptsQuery = selector({
  key: "receipts",
  get: ({ get }) => get(receiptsRespQuery)?.data ?? [],
});

export const receiptsCountQuery = selector({
  key: "receipts/count",
  get: ({ get }) => get(receiptsRespQuery)?.page?.total ?? 0,
});
