import { monthState, searchUIDState } from "components";
import { RecoilState, atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { GiftRecordsParams, GiftSearchType, fetchGiftRecords } from "services";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

import { errorState } from "./error";
import { pageSizeState } from "./pagination";

type GiftRecordsProps = {
  type: GiftSearchType;
  uid?: number | string;
  pageState: RecoilState<number>;
};

export const giftRecordsRespQuery = selectorFamily({
  key: "gift/records/resp",
  get:
    (props: GiftRecordsProps) =>
    async ({ get }) => {
      const page = get(props.pageState);
      const pageSize = get(pageSizeState);

      const { type, uid } = props;

      const params: GiftRecordsParams = {
        page,
        pageSize,
        type,
      };

      if (!uid) {
        const searchTimestamps = getSearchTimestampsFrom(get(monthState));
        params.createdStart = searchTimestamps.createdStart;
        params.createdEnd = searchTimestamps.createdEnd;
      } else {
        params.uid = +uid;
      }

      try {
        const resp = await fetchGiftRecords(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const giftsPageState = atom({
  key: "gifts/page",
  default: 1,
});

export const giftRecordsQuery = selector({
  key: "gift/records",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        giftRecordsRespQuery({
          type: GiftSearchType.LIST,
          uid,
          pageState: giftsPageState,
        })
      )?.data ?? []
    );
  },
});

export const giftRecordsCountQuery = selector({
  key: "gift/records/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return (
      get(
        giftRecordsRespQuery({
          type: GiftSearchType.LIST,
          uid,
          pageState: giftsPageState,
        })
      )?.page?.total ?? 0
    );
  },
});
