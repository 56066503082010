import { number } from "@recoiljs/refine";
import { atom, selectorFamily } from "recoil";
import { syncEffect } from "recoil-sync";

export const pageSizeState = atom<number>({
  key: "page_size",
  default: parseInt(process.env.REACT_APP_PAGESIZE ?? "30"),
  effects: [syncEffect({ refine: number() })],
});

export const maxPageState = selectorFamily({
  key: "max_pages",
  get:
    (total: number) =>
    async ({ get }) => {
      return Math.ceil(total / get(pageSizeState));
    },
});
