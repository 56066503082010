import { atom, selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { subStatus } from 'types';
import { errorState } from './error';
import { getErrorMessage, getSearchTimestampsFrom } from 'utils';
import { checkAllNotification, SubsParams } from 'services';
import { pageSizeState } from './pagination';
import { monthState, searchOriginalID, searchUIDState, subStatuses, subStatusState } from 'components';


export const notificationPageState = atom({
    key: "notification/page",
    default: 1,
  });
export const notificationRespQuery = selector({
    key: "notification/resp",
    get: async ({ get }) => {
      const page = get(notificationPageState);
      const pageSize = get(pageSizeState);
  
      const uid = get(searchUIDState);
      const originalTransactionId = get(searchOriginalID);
      const source = subStatuses[get(subStatusState)];
  
      const params: SubsParams = {
        page,
        pageSize,
      };
  
      if (!uid && !originalTransactionId) {
        if (source === subStatus.AppStore) {
          params.source = 1;
        } else if(source === subStatus.GooglePlay) {
          params.source = 2;
        } 
  
        const month = get(monthState);
        const searchTimestamps = getSearchTimestampsFrom(month);
  
        params.createdStart = searchTimestamps.createdStart;
        params.createdEnd = searchTimestamps.createdEnd;
      } else {
        params.uid = uid 
        params.originalTransactionId = originalTransactionId
      }
      try {
        const resp = await checkAllNotification(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });
export const notificationQuery = selector({
    key: "notificationQuery",
    get: ({ get }) => {
      return get(notificationRespQuery)?.data ?? [];
    },
  });
export const notificationCountQuery = selector({
    key: "notification/count",
    get: ({ get }) => {
      return get(notificationRespQuery)?.page?.total ?? 0;
    },
  });

