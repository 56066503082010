import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import {
  BioStatusBadge,
  CallStatusBadge,
  EmptyView,
  Pagination,
  ProfileStatusBadge,
  SubmitButton,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { Suspense, useRef } from "react";
import { LiaCoinsSolid } from "react-icons/lia";
import { RxArrowLeft, RxPlusCircled } from "react-icons/rx";
import { SlCallIn, SlCallOut } from "react-icons/sl";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  Bio,
  CallRole,
  CallSearchType,
  ProfileDetails,
  ReportStatus,
  editProfileCoins,
  reviewBios,
  reviewStories,
} from "services";
import {
  CallsRespProps,
  biosRequestIdState,
  errorState,
  isBioSelectedState,
  maxOrderPageState,
  orderPage,
  profileBiosQuery,
  profileBiosToSubmitQuery,
  profileBlocksCountQuery,
  profileBlocksPageState,
  profileBlocksQuery,
  profileCallsCountQuery,
  profileCallsQuery,
  profileDirectCallsPageState,
  profileFollowersCountQuery,
  profileFollowersPageState,
  profileFollowersQuery,
  profileFollowingCountQuery,
  profileFollowingPageState,
  profileFollowingQuery,
  profileGiftsCountQuery,
  profileGiftsPageState,
  profileGiftsQuery,
  profileLikesCountQuery,
  profileLikesPageState,
  profileLikesQuery,
  profileQuery,
  profileRandomCallsPageState,
  profileReportsCountQuery,
  profileReportsPageState,
  profileReportsQuery,
  profileRequestIdState,
  profileStoriesCountQuery,
  profileStoriesQuery,
  profileStoriesToSubmitQuery,
  rejectedBiosIdsState,
  rejectedStoriesIdsState,
  storiesPageState,
  storiesRequestIdState,
  userSubOrder,
  userSubSelectorFamily
} from "state";
import { BioStatus, Gender } from "types";
import { getErrorMessage, timestampMsToDateStr } from "utils";
import { StoriesWarp } from "./Stories";

export function Profile() {
  const params = useParams();

  const id = params["id"];

  if (!id) return <Text>Invalid ID</Text>;

  return (
    <Stack w="100%">
      <Suspense fallback={<Spinner m={4} />}>
        <HStack
          px={4}
          py={2}
          top="0"
          position="sticky"
          bg="white"
          zIndex={99}>
          <ProfileHeader id={+id} />
        </HStack>

        <Box p={4}>
          <ProfileBioSection id={+id} />
        </Box>
        <ProfileContent id={+id} />

        <Spacer />
      </Suspense>
    </Stack>
  );
}

function ProfileHeader({ id }: { id: number }) {
  const profile = useRecoilValue(profileQuery(id));

  if (!profile) return <></>;

  return (
    <>
      <ProfileBackButton />
      <ProfileBasicInfo profile={profile} />
      <Spacer />
      <ProfileCoins profile={profile} />
    </>
  );
}

function ProfileBackButton() {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <IconButton
      onClick={handleClickBack}
      icon={<RxArrowLeft />}
      aria-label="Back"
    />
  );
}

type ProfileCoinEditFormValues = {
  type: number;
  quantity: number;
};

function ProfileCoins({ profile }: { profile: ProfileDetails }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const updateRequestId = useSetRecoilState(profileRequestIdState);

  const formik = useFormik<ProfileCoinEditFormValues>({
    initialValues: {
      type: 1,
      quantity: 0,
    },
    onSubmit: async (values) => {
      try {
        await editProfileCoins({ ...values, id: profile.id });
        updateRequestId(dayjs().valueOf());
        onClose();
        formik.resetForm();
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <Button
        colorScheme="purple"
        py={2}
        px={4}
        borderRadius={44}
        onClick={onOpen}>
        <HStack spacing={2}>
          <Icon as={LiaCoinsSolid} />
          <Text
            fontSize={18}
            fontWeight={"medium"}>
            {profile.coinQuantity}
          </Text>
          <Icon as={RxPlusCircled} />
        </HStack>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Coins Balance: {profile.coinQuantity}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={formik.handleSubmit}>
            <ModalBody pb={6}>
              <HStack>
                <FormControl>
                  <FormLabel>+ / -</FormLabel>
                  <Select
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}>
                    <option value="1">+</option>
                    <option value="0">-</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Coins</FormLabel>
                  <Input
                    ref={initialRef}
                    name="quantity"
                    type="number"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    placeholder="1 - 1,000,000"
                  />
                </FormControl>
              </HStack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="purple"
                mr={3}
                type="submit"
                isLoading={formik.isSubmitting}
                isDisabled={formik.values.quantity === 0}>
                Save
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

function ProfileBioSection({ id }: { id: number }) {
  const bios = useRecoilValue(profileBiosQuery(id));

  return (
    <>
      {bios.length > 0 && (
        <ProfileBioView
          bio={bios[0]}
          uid={id}
        />
      )}
    </>
  );
}

function ProfileBioView({ uid, bio }: { uid: number; bio: Bio }) {
  const isSelected = useRecoilValue(isBioSelectedState(bio.id));
  const setRejectedBioIds = useSetRecoilState(rejectedBiosIdsState);

  const handleSelectBio = () => {
    if (![BioStatus.Approved, BioStatus.Pending].includes(bio.status)) return;

    setRejectedBioIds((prev) => {
      const rejectedIds = [...prev];

      // toggle
      const index = rejectedIds.indexOf(bio.id);
      index !== -1 ? rejectedIds.splice(index, 1) : rejectedIds.push(bio.id);

      return rejectedIds;
    });
  };

  return (
    <HStack alignItems={"flex-start"}>
      <Stack maxW="375px">
        <Text
          fontSize={18}
          cursor="pointer"
          bg={isSelected ? "red.100" : undefined}
          color={isSelected ? "red" : undefined}
          textDecoration={isSelected ? "line-through" : undefined}
          onClick={handleSelectBio}>
          {bio.content}
        </Text>
        <HStack>
          <BioStatusBadge status={bio.status} />
          <Text
            fontSize={12}
            opacity={0.4}>
            Updated At: {timestampMsToDateStr(bio.updatedAt)}
          </Text>
        </HStack>
      </Stack>

      {[BioStatus.Approved, BioStatus.Pending].includes(bio.status) && (
        <SubmitButton
          state={profileBiosToSubmitQuery(uid)}
          requestIdState={biosRequestIdState}
          rejectedIdsState={rejectedBiosIdsState}
          api={reviewBios}
        />
      )}
    </HStack>
  );
}

function ProfileContent({ id }: { id: number }) {
  return (
    <Tabs isLazy>
      <TabList>
        <Tab>Basic</Tab>
        <Tab>Stories</Tab>
        <Tab>Following</Tab>
        <Tab>Followers</Tab>
        <Tab>Liked Me</Tab>
        <Tab>Random Calls</Tab>
        <Tab>Direct Calls</Tab>
        <Tab>Gifts</Tab>
        <Tab>Blocked</Tab>
        <Tab>Reports</Tab>
        <Tab>Sub payment</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <BasicPanel />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <StoriesPanel id={id} />
          </Suspense>
        </TabPanel>

        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <FollowingPanel id={+id} />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <FollowersPanel id={+id} />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <LikesPanel id={+id} />
          </Suspense>
        </TabPanel>

        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <CallsPanel
              uid={id}
              type={CallSearchType.RANDOM_MY}
              pageState={profileRandomCallsPageState}
            />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <CallsPanel
              uid={id}
              type={CallSearchType.DIRECT_MY}
              pageState={profileDirectCallsPageState}
            />
          </Suspense>
        </TabPanel>

        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <GiftsPanel id={+id} />
          </Suspense>
        </TabPanel>

        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <BlocksPanel id={+id} />
          </Suspense>
        </TabPanel>

        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <ReportsPanel id={+id} />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<Spinner />}>
            <Stack>
              <SubTable id={+id} />
            </Stack>
          </Suspense>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function BasicPanel() {
  const params = useParams();
  const id = params["id"] ?? "0";
  const profile = useRecoilValue(profileQuery(+id));
  //用户的现有订阅信息
  const subData = useRecoilValue(userSubSelectorFamily(+id))
  if (!profile) return <></>;
  return (
    <>
      <Wrap spacing={4}>
        <WrapItem>
          <BasicInfoView
            label="Looking For"
            value={profile.matchFilter.genders
              .map((gValue) => Gender[gValue])
              .join(", ")}
          />
        </WrapItem>
        <WrapItem>
          <BasicInfoView
            label="Search In"
            value={profile.matchFilter.region}
          />
        </WrapItem>
        <WrapItem>
          <BasicInfoView
            label="Languages"
            value={profile.languages.join(", ")}
          />
        </WrapItem>
        <WrapItem>
          <BasicInfoView
            label="Interests"
            value={profile.interests.join(", ")}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Last Login By"
            value={profile.loginInfo.source}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Last Login On:"
            value={profile.loginInfo.hardware}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Last Login IP"
            value={profile.loginInfo.ip}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Last Login At"
            value={timestampMsToDateStr(profile.loginInfo.activeTime)}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Created At"
            value={timestampMsToDateStr(profile.createdAt)}
          />
        </WrapItem>

        <WrapItem>
          <BasicInfoView
            label="Updated At"
            value={timestampMsToDateStr(profile.updatedAt)}
          />
        </WrapItem>
      </Wrap>
      <Stack direction='column' h='100px' py={4}>
        <Divider orientation='horizontal' height="10px" />
        <Text fontSize='2xl' as='b'>Subscription</Text>
        <Wrap spacing={4}>
          <WrapItem>
            <BasicInfoView
              label="Procut ID"
              value={subData.data.productId}
            />
          </WrapItem>
          <WrapItem>
            <BasicInfoView
              label="Original ID"
              value={subData.data.originalTransactionId}
            />
          </WrapItem>
          <WrapItem>
            <BasicInfoView
              label="Expiry Date "
              value={timestampMsToDateStr(Number(subData.data.expiresDate))}
            />
          </WrapItem>
          <WrapItem>
            <BasicInfoView
              label="Original Purchase Date "
              value={timestampMsToDateStr(Number(subData.data.originalPurchaseDate))}
            />
          </WrapItem>
          <WrapItem>
            <BasicInfoView
              label="Transaction ID"
              value={subData.data.transactionId}
            />
          </WrapItem>
        </Wrap>
      </Stack>
    </>
  );
}

type BasicInfoViewProps = {
  label: string;
  value: string;
};

function BasicInfoView({ label, value }: BasicInfoViewProps) {
  return (
    <Stack
      spacing={0}
      w="256px">
      <Text
        opacity={0.5}
        fontSize={14}>
        {label}
      </Text>
      <Text fontWeight={"medium"}>{value}</Text>
    </Stack>
  );
}

function StoriesPanel({ id }: { id: number }) {
  return (
    <Stack>
      <HStack>
        <Spacer />
        <SubmitButton
          state={profileStoriesToSubmitQuery(id)}
          requestIdState={storiesRequestIdState}
          rejectedIdsState={rejectedStoriesIdsState}
          api={reviewStories}
        />
      </HStack>
      <StoriesWarp
        state={profileStoriesQuery(id)}
        rejectedStoriesIdsState={rejectedStoriesIdsState}
      />
      <Spacer />
      <Pagination
        state={storiesPageState}
        totalState={profileStoriesCountQuery(id)}
      />
    </Stack>
  );
}

function FollowingPanel({ id }: { id: number }) {
  const following = useRecoilValue(profileFollowingQuery(id));

  return (
    <Stack>
      {following.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Follow At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {following.map((item) => (
                  <Tr key={item.recipient.id}>
                    <Td>
                      <ProfileBasicInfo profile={item.recipient} />
                    </Td>
                    <Td>{timestampMsToDateStr(item.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileFollowingPageState}
            totalState={profileFollowingCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}

function FollowersPanel({ id }: { id: number }) {
  const followers = useRecoilValue(profileFollowersQuery(id));

  return (
    <Stack>
      {followers.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Follow At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {followers.map((follower) => (
                  <Tr key={follower.initiator.id}>
                    <Td>
                      <ProfileBasicInfo profile={follower.initiator} />
                    </Td>
                    <Td>{timestampMsToDateStr(follower.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileFollowersPageState}
            totalState={profileFollowersCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}

function LikesPanel({ id }: { id: number }) {
  const likes = useRecoilValue(profileLikesQuery(id));

  return (
    <Stack>
      {likes.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Liked At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {likes.map((like) => (
                  <Tr key={like.initiator.id}>
                    <Td>
                      <HStack key={like.initiator.id}>
                        <Avatar
                          src={like.initiator.avatar.url}
                          name={like.initiator.firstName}
                        />
                        <Stack spacing={0}>
                          <HStack>
                            <Text
                              fontSize={"18"}
                              fontWeight={"medium"}>
                              {like.initiator.firstName}
                            </Text>
                            <ProfileStatusBadge
                              status={like.initiator.status}
                            />
                          </HStack>
                          <Text fontSize={12}>
                            {[
                              like.initiator.age,
                              like.initiator.gender &&
                              Gender[like.initiator.gender],
                              like.initiator.region,
                            ].join(", ")}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>{timestampMsToDateStr(like.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileLikesPageState}
            totalState={profileLikesCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}

function CallsPanel({ uid, type, pageState }: CallsRespProps) {
  const calls = useRecoilValue(profileCallsQuery({ type, uid, pageState }));

  return (
    <Stack>
      {calls.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Channel ID</Th>
                  <Th>Role</Th>
                  <Th>Status</Th>
                  <Th>Duration</Th>
                  <Th>Call At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {calls.map((call) => (
                  <Tr key={call.id}>
                    <Td>
                      <ProfileBasicInfo profile={call.user} />
                    </Td>
                    <Td>{call.channelId}</Td>
                    <Td>
                      <Icon
                        as={call.role === CallRole.Out ? SlCallOut : SlCallIn}
                      />
                    </Td>
                    <Td>
                      <CallStatusBadge status={call.status} />
                    </Td>
                    <Td>{call.duration} seconds</Td>

                    <Td>{timestampMsToDateStr(call.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={pageState}
            totalState={profileCallsCountQuery({
              type,
              uid,
              pageState,
            })}
          />
        </>
      )}
    </Stack>
  );
}

function GiftsPanel({ id }: { id: number }) {
  const gifts = useRecoilValue(profileGiftsQuery(id));

  return (
    <Stack>
      {gifts.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>From</Th>
                  <Th>Gift</Th>
                  <Th>Costs</Th>
                  <Th>Sent At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {gifts.map((gift) => (
                  <Tr key={gift.initiator.id}>
                    <Td>
                      <ProfileBasicInfo profile={gift.initiator} />
                    </Td>
                    <Td>
                      <HStack>
                        <Image
                          src={gift.gift.cover}
                          w="44px"
                          h="44px"
                        />
                        <Text>{gift.gift.name}</Text>
                      </HStack>
                    </Td>
                    <Td>{gift.gift.coins}</Td>
                    <Td>{timestampMsToDateStr(gift.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileGiftsPageState}
            totalState={profileGiftsCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}

function BlocksPanel({ id }: { id: number }) {
  const blocks = useRecoilValue(profileBlocksQuery(id));

  return (
    <Stack>
      {blocks.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Blocked At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {blocks.map((block) => (
                  <Tr key={block.recipient.id}>
                    <Td>
                      <ProfileBasicInfo profile={block.recipient} />
                    </Td>
                    <Td>{timestampMsToDateStr(block.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileBlocksPageState}
            totalState={profileBlocksCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}

function ReportsPanel({ id }: { id: number }) {
  const reports = useRecoilValue(profileReportsQuery(id));

  return (
    <Stack>
      {reports.length === 0 ? (
        <EmptyView />
      ) : (
        <>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Reported By</Th>
                  <Th>Reason</Th>
                  <Th>Status</Th>
                  <Th>Reported At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {reports.map((report) => (
                  <Tr key={report.user.id}>
                    <Td>
                      <ProfileBasicInfo profile={report.user} />
                    </Td>
                    <Td>{report.reason}</Td>
                    <Td>{ReportStatus[report.status]}</Td>
                    <Td>{timestampMsToDateStr(report.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            state={profileReportsPageState}
            totalState={profileReportsCountQuery(id)}
          />
        </>
      )}
    </Stack>
  );
}
//用户订阅记录
function SubTable({ id }: { id: number }) {
  const subData = useRecoilValue(userSubOrder(id)).data || []
  const subPageState = useRecoilValue(userSubOrder(id)).page || {}
  const setCurrentPage = useSetRecoilState(orderPage);
  const maxPage = useRecoilValue(maxOrderPageState(subPageState.total))
  const handleClickNext = () => {
    setCurrentPage((next) => {
      return Math.min(next + 1, maxPage);
    });
  };
  const handleClickPer = () => {
    setCurrentPage((pre) => {
      return Math.max(pre - 1, 1);
    });
  };
  return (
    <TableContainer>
      <Table variant="simple" size="m">
        <Thead>
          <Tr>
            <Th>Num</Th>
            <Th>Transaction ID</Th>
            <Th>Original ID</Th>
            <Th>Source</Th>
            <Th>Create Time</Th>
            <Th>Product ID</Th>
          </Tr>
        </Thead>
        <Tbody height="30%">
          {subData.map((value: any, index: number) => {
            return (
              <Tr _hover={{ bg: "red.100" }} height="60px" key={value.id}>
                <Td paddingRight="25px">{++index}</Td>
                <Td paddingRight="25px">{value.transactionId}</Td>
                <Td paddingRight="25px">{value.originalTransactionId}</Td>
                <Td paddingRight="25px">{value.source === 1 ? 'App Store' : 'Google Play'}</Td>
                <Td paddingRight="25px">{timestampMsToDateStr(value.createdAt)}</Td>
                <Td>{value.productId}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <HStack bg={"whiteAlpha.800"} position={"fixed"}
        bottom={0}
        h={"54px"}
        pr={2}
        p={2}>
        <Button onClick={handleClickPer} >Pre</Button>
        <Text>{useRecoilValue(orderPage)}/</Text><Text>{useRecoilValue(maxOrderPageState(subPageState.total))}</Text>
        <Button onClick={handleClickNext}>Next</Button>
      </HStack>
    </TableContainer>
  )
}