import { Link, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_URI_PROFILES } from "utils";

interface ProfileIdProps extends LinkProps {
  uid: number;
}

export function ProfileId(props: ProfileIdProps) {
  const { uid, children, ...linkProps } = props;
  return (
    <Link
      as={RouterLink}
      to={`${ROUTE_URI_PROFILES}/${uid}`}
      {...linkProps}>
      {children}
    </Link>
  );
}
