import {
  Icon,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import {
  CallStatusBadge,
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  UIDSearchBar,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import { Suspense } from "react";
import { SlCallIn, SlCallOut } from "react-icons/sl";
import {
  RecoilValueReadOnly,
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import { syncEffect } from "recoil-sync";
import { Call, CallRole } from "services";
import {
  directCallsCountQuery,
  directCallsPageState,
  directCallsQuery,
  randomCallsCountQuery,
  randomCallsPageState,
  randomCallsQuery,
} from "state";
import { CallType } from "types";
import { timestampMsToDateStr } from "utils";

export const callsTabState = atom({
  key: "calls_tab",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const callsTabs = [CallType.Random, CallType.Direct];

export function Calls() {
  const [tabIndex, setTabIndex] = useRecoilState(callsTabState);

  const countQuery =
    tabIndex === 0 ? randomCallsCountQuery : directCallsCountQuery;
  const pageState =
    tabIndex === 0 ? randomCallsPageState : directCallsPageState;

  const resetPage = useResetRecoilState(pageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={countQuery}>
        <UIDSearchBar onChange={resetPage} />
        <MonthFilter />
      </MainHeader>

      <Tabs
        isLazy
        defaultIndex={tabIndex}
        onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Random</Tab>
          <Tab>Direct</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Suspense fallback={<Spinner />}>
              <CallsTable state={randomCallsQuery} />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Spinner />}>
              <CallsTable state={directCallsQuery} />
            </Suspense>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Spacer />
      <MainFooter>
        <Pagination
          totalState={countQuery}
          state={pageState}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

type DirectCallsPanelProps = {
  state: RecoilValueReadOnly<Call[]>;
};

function CallsTable({ state }: DirectCallsPanelProps) {
  const calls = useRecoilValue(state);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>User</Th>
            <Th>Channel ID</Th>
            <Th>Role</Th>
            <Th>Duration</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {calls.map((call) => (
            <Tr key={call.id}>
              <Td>{call.id}</Td>
              <Td>
                <ProfileBasicInfo profile={call.user} />
              </Td>
              <Td>{call.channelId}</Td>
              <Td>
                <Icon as={call.role === CallRole.Out ? SlCallOut : SlCallIn} />
              </Td>
              <Td>{call.duration} s</Td>

              <Td>
                <CallStatusBadge status={call.status} />
              </Td>
              <Td>{timestampMsToDateStr(call.createdAt)}</Td>
              <Td>{timestampMsToDateStr(call.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
