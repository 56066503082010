import { Button } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Suspense, useState } from "react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "state";
import { ReviewBody } from "types";
import { getErrorMessage } from "utils";

type SubmitButtonProps = {
  state: RecoilValueReadOnly<ReviewBody>;
  requestIdState: RecoilState<number>;
  rejectedIdsState: RecoilState<number[]>;
  api: (reviewBody: ReviewBody) => Promise<any>;
  onFinished?: () => void;
};

export function SubmitButton(props: SubmitButtonProps) {
  return (
    <Suspense
      fallback={
        <Button
          colorScheme="red"
          disabled>
          Submit
        </Button>
      }>
      <SubmitButtonContent {...props} />
    </Suspense>
  );
}
export function SubmitButtonContent(props: SubmitButtonProps) {
  const reviewBody = useRecoilValue(props.state);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const refreshRequestId = useSetRecoilState(props.requestIdState);
  const resetRejectedIds = useResetRecoilState(props.rejectedIdsState);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await props.api(reviewBody);

      resetRejectedIds();

      props.onFinished
        ? props.onFinished()
        : refreshRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <Button
      colorScheme={"red"}
      onClick={handleSubmit}
      isLoading={isSubmitting}>
      Submit
    </Button>
  );
}
