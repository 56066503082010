export enum RelationshipType {
  Follow,
  Like,
  Block,
}

export enum RelationshipStatus {
  Enable,
  Disable,
}
