export enum DeviceStatus {
  All = 99,
  Enabled = 1,
  Disabled = 2,
}

export enum Platform {
  iOS = 1,
  Android,
}
