type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  // return (
  //   typeof error === "object" &&
  //   error !== null &&
  //   "message" in error &&
  //   typeof (error as Record<string, unknown>).message === "string"
  // );
  return error instanceof Error && typeof error?.message === "string";
}

// function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
//   if (isErrorWithMessage(maybeError)) return maybeError;
//
//   try {
//     return new Error(JSON.stringify(maybeError));
//   } catch {
//     return new Error(String(maybeError));
//   }
// }

export function getErrorMessage(error: unknown) {
  // return toErrorWithMessage(error).message;
  if (isErrorWithMessage(error)) {
    return error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return String(error);
}
