import { privateAxios } from "services";
import {
  MediaStatus,
  PhotosResp,
  ReviewBody,
  SearchParams,
  StoriesResp,
} from "types";

const API_URL_PHOTO_SEARCH = "/photo/search";
const API_URL_PHOTO_REVIEW = "/photo/review";

const API_URL_STORY_SEARCH = "/story/search";
const API_URL_STORY_REVIEW = "/story/review";

export const fetchPhotos = (params: SearchParams<MediaStatus[]>) => {
  // const resp = await privateAxios.post<Photo[]>(API_URL_PHOTO_SEARCH, params)
  return privateAxios.post<PhotosResp>(API_URL_PHOTO_SEARCH, params);
};

export const fetchStories = (params: SearchParams<MediaStatus[]>) => {
  return privateAxios.post<StoriesResp>(API_URL_STORY_SEARCH, params);
};

export const reviewPhotos = (body: ReviewBody) => {
  return privateAxios.post(API_URL_PHOTO_REVIEW, body);
};
export const reviewStories = (body: ReviewBody) => {
  return privateAxios.post(API_URL_STORY_REVIEW, body);
};
