import {
  Avatar,
  Box,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  ProfileId,
  ProfileStatusBadge,
  ProfileStatusFilter,
  UIDSearchBar,
  UUIDSearchBar,
} from "components";
import { Suspense } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { profilesCountQuery, profilesPageState, profilesQuery } from "state";
import { Gender } from "types";
import { timestampMsToDateStr } from "utils";

export function Profiles() {
  const resetPage = useResetRecoilState(profilesPageState);

  return (
    <Stack w="100%">
      <MainHeader totalState={profilesCountQuery}>
        <UUIDSearchBar onChange={resetPage} />
        <UIDSearchBar onChange={resetPage} />
        <ProfileStatusFilter />
        <MonthFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <ProfilesTable />
        </Suspense>
      </Box>

      <Spacer />
      <MainFooter>
        <Pagination
          state={profilesPageState}
          totalState={profilesCountQuery}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function ProfilesTable() {
  const profiles = useRecoilValue(profilesQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th w="1">ID</Th>
            <Th w="1">Avatar</Th>
            <Th>Name</Th>
            <Th>Gender</Th>
            <Th>Age</Th>
            <Th>Region</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {profiles.map((profile) => (
            <Tr key={profile.id}>
              <Td>
                <ProfileId uid={profile.id}>{profile.id}</ProfileId>
              </Td>
              <Td>
                <ProfileId uid={profile.id}>
                  <Avatar
                    src={profile.avatar.url}
                    name={profile.firstName}
                  />
                </ProfileId>
              </Td>
              <Td>
                <ProfileId uid={profile.id}>{profile.firstName}</ProfileId>
              </Td>
              <Td>{profile.gender || profile.gender === 0 ? Gender[profile.gender] : "--"}</Td>
              <Td>{profile.age ? profile.age : "--"}</Td>
              <Td>{profile.region}</Td>
              <Td>
                <ProfileStatusBadge status={profile.status} />
              </Td>
              <Td>{timestampMsToDateStr(profile.createdAt)}</Td>
              <Td>{timestampMsToDateStr(profile.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
