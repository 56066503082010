import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

// timestampMsToDateStr 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const timestampMsToDateStr = (timestampMs?: number): string => {
  return !timestampMs
    ? "--"
    : dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};

// 时间戳转年龄
export const timestampMsToAge = (timestampMs: number): number => {
  return dayjs().diff(dayjs(timestampMs), "year");
};

// 根据月份计算查询起止时间戳
// 月份格式：YYYYMM
export const getSearchTimestampsFrom = (month: string | number) => {
  const date = dayjs(String(month), "YYYYMM");

  return {
    createdStart: date.valueOf(),
    createdEnd: date.month(date.month() + 1).valueOf(),
  };
};
