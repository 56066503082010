import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CaptchaStatusBadge,
  MainFooter,
  MainHeader,
  Pagination,
} from "components";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { Suspense } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FiChevronDown, FiDelete } from "react-icons/fi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  Captcha,
  CaptchaSource,
  CaptchaToCreate,
  CaptchaToUpdate,
  createCaptcha,
  deleteCaptcha,
  updateCaptcha,
} from "services";
import {
  captchasCountQuery,
  captchasPageState,
  captchasQuery,
  captchasRequestIdState,
  errorState,
} from "state";
import { getErrorMessage, timestampMsToDateStr } from "utils";

const dayjsFormat = "MM/DD/YYYY HH:mm:ss";

export function Captchas() {
  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={captchasCountQuery}>
        <AddCaptchaButton />
      </MainHeader>

      <Box
        px={4}
        opacity={0.5}>
        <Text>Set a fixed verification code for the test account.</Text>
      </Box>

      <Box px={4}>
        <Suspense fallback={<Spinner />}>
          <CaptchasTable />
        </Suspense>
      </Box>
      <Spacer />
      <MainFooter>
        <Pagination
          totalState={captchasCountQuery}
          state={captchasPageState}
        />
        <Spacer />
      </MainFooter>
    </Stack>
  );
}

function CaptchasTable() {
  const captchas = useRecoilValue(captchasQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Source</Th>
            <Th>Account</Th>
            <Th>Code</Th>
            <Th>Expired At</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {captchas.map((captcha) => (
            <Tr key={captcha.id}>
              <Td>{captcha.id}</Td>
              <Td>{captcha.source}</Td>
              <Td>{captcha.account}</Td>
              <Td>{captcha.code}</Td>
              <Td>{timestampMsToDateStr(captcha.expiredAt)}</Td>
              <Td>
                <CaptchaStatusBadge status={captcha.status} />
              </Td>
              <Td>{timestampMsToDateStr(captcha.createdAt)}</Td>
              <Td>{timestampMsToDateStr(captcha.updatedAt)}</Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<FiChevronDown />}
                    colorScheme="gray">
                    Actions
                  </MenuButton>
                  <MenuList>
                    <EditCaptchaMenuItem captcha={captcha} />

                    <MenuDivider />
                    <DeleteCaptchaMenuItem id={captcha.id} />
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function AddCaptchaButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const formik = useFormik({
    initialValues: {
      source: CaptchaSource.Email,
      account: "",
      code: "",
      expiredAt: dayjs().format(dayjsFormat),
    },
    onSubmit: async (values) => {
      const captchaToCreate: CaptchaToCreate = {
        source: values.source,
        account: values.account,
        code: values.code,
        expiredAt: dayjs(values.expiredAt).valueOf(),
      };
      try {
        await createCaptcha(captchaToCreate);
        updateRequestId(dayjs().valueOf());
        formik.resetForm();
        onClose();
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <Button onClick={onOpen}>Add Captcha</Button>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Create New Captcha</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Source</FormLabel>
                  <Input
                    ref={initialRef}
                    name="source"
                    type="text"
                    value={formik.values.source}
                    onChange={formik.handleChange}
                    placeholder="Source: Email / Phone"
                    disabled
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Account</FormLabel>
                  <Input
                    name="account"
                    type="text"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    placeholder="Account"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Code</FormLabel>
                  <Input
                    name="code"
                    type="text"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    placeholder="Code"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Expired At</FormLabel>
                  <Input
                    name="expiredAt"
                    type="text"
                    value={formik.values.expiredAt}
                    onChange={formik.handleChange}
                    placeholder="Expired At"
                  />
                  <FormHelperText>Format: {dayjsFormat}</FormHelperText>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formik.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

function DeleteCaptchaMenuItem({ id }: { id: number }) {
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const handleDelete = async () => {
    try {
      await deleteCaptcha(id);
      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  };

  return (
    <MenuItem
      icon={<FiDelete />}
      color="red"
      onClick={handleDelete}>
      Delete
    </MenuItem>
  );
}

function EditCaptchaMenuItem({ captcha }: { captcha: Captcha }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const formik = useFormik({
    initialValues: {
      source: captcha.source,
      account: captcha.account,
      code: captcha.code,
      expiredAt: dayjs(captcha.expiredAt).format(dayjsFormat),
    },
    onSubmit: async (values) => {
      const captchaToUpdate: CaptchaToUpdate = {
        id: captcha.id,
        source: values.source,
        account: values.account,
        code: values.code,
        expiredAt: dayjs(values.expiredAt).valueOf(),
      };
      try {
        await updateCaptcha(captchaToUpdate);
        updateRequestId(dayjs().valueOf());
        onClose();
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <MenuItem
        icon={<AiOutlineEdit />}
        onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          <ModalContent>
            <ModalHeader>Edit Captcha</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Account</FormLabel>
                  <Input
                    ref={initialRef}
                    name="account"
                    type="text"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    placeholder="Account"
                    disabled
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Code</FormLabel>
                  <Input
                    name="code"
                    type="number"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    placeholder="Code"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Secret</FormLabel>
                  <Input
                    name="expiredAt"
                    type="text"
                    value={formik.values.expiredAt}
                    onChange={formik.handleChange}
                    placeholder="Expired At"
                  />
                  <FormHelperText>Format: {dayjsFormat}</FormHelperText>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formik.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
