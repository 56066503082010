import { Profile, privateAxios } from "services";
import { BioStatus, Resp, ReviewBody, SearchParams, Timestamp } from "types";

const API_URL_BIO_SEARCH = "/bio/search";
const API_URL_BIO_REVIEW = "/bio/review";

export type Bio = {
  id: number;
  content: string;
  status: BioStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  user?: Profile;
};

export type BiosResp = Resp<Bio[]>;

export const fetchBios = (params: SearchParams<BioStatus[]>) => {
  return privateAxios.post<BiosResp>(API_URL_BIO_SEARCH, params);
};

export const reviewBios = (body: ReviewBody) => {
  return privateAxios.post(API_URL_BIO_REVIEW, body);
};
