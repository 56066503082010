import {
  Box,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  BioStatusBadge,
  BioStatusFilter,
  MainFooter,
  MainHeader,
  MonthFilter,
  Pagination,
  SubmitButton,
  bioStatusOptionState,
} from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import dayjs from "dayjs";
import { Suspense } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Bio, reviewBios } from "services";
import {
  biosCountQuery,
  biosPageState,
  biosQuery,
  biosRequestIdState,
  biosToSubmitQuery,
  isBioSelectedState,
  rejectedBiosIdsState,
} from "state";
import { BioStatus } from "types";
import { timestampMsToDateStr } from "utils";

export function Bios() {
  const updateRequestId = useSetRecoilState(biosRequestIdState);
  const bioStatusOption = useRecoilValue(bioStatusOptionState);

  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={biosCountQuery}>
        {bioStatusOption !== 0 && <MonthFilter />}
        <BioStatusFilter />
      </MainHeader>

      <Box px={4}>
        <Suspense fallback={<Spinner size="sm" />}>
          <BiosTable />
        </Suspense>
      </Box>

      <Spacer />
      <MainFooter>
        <Pagination
          totalState={biosCountQuery}
          state={biosPageState}
        />
        <Spacer />
        {[0, 1].includes(bioStatusOption) && (
          <SubmitButton
            state={biosToSubmitQuery}
            requestIdState={biosRequestIdState}
            rejectedIdsState={rejectedBiosIdsState}
            api={reviewBios}
            onFinished={() => updateRequestId(dayjs().valueOf())}
          />
        )}
      </MainFooter>
    </Stack>
  );
}

function BiosTable() {
  const bios = useRecoilValue(biosQuery);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>User</Th>
            <Th>Bio</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {bios.map((bio) => (
            <Tr key={bio.id}>
              <Td>{bio.id}</Td>
              <Td>
                {bio.user ? <ProfileBasicInfo profile={bio.user} /> : "--"}
              </Td>

              <BioContentView bio={bio} />

              <Td>
                <BioStatusBadge status={bio.status} />
              </Td>
              <Td>{timestampMsToDateStr(bio.createdAt)}</Td>
              <Td>{timestampMsToDateStr(bio.updatedAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function BioContentView({ bio }: { bio: Bio }) {
  const isSelected = useRecoilValue(isBioSelectedState(bio.id));
  const setRejectedBioIds = useSetRecoilState(rejectedBiosIdsState);

  const handleSelectBio = () => {
    if (![BioStatus.Approved, BioStatus.Pending].includes(bio.status)) return;

    setRejectedBioIds((prev) => {
      const rejectedIds = [...prev];

      // toggle
      const index = rejectedIds.indexOf(bio.id);
      index !== -1 ? rejectedIds.splice(index, 1) : rejectedIds.push(bio.id);

      return rejectedIds;
    });
  };

  return (
    <Td
      cursor="pointer"
      _hover={{ bg: "red.100" }}
      bg={isSelected ? "red.100" : undefined}
      color={isSelected ? "red" : undefined}
      textDecoration={isSelected ? "line-through" : undefined}
      onClick={handleSelectBio}>
      <Text whiteSpace="wrap">{bio.content}</Text>
    </Td>
  );
}
