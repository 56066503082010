export enum CallType {
  Random,
  Direct,
}

export enum CallStatus {
  Pending,
  Approved,
  Rejected,
  Declined,
  Timeout,
  Canceled,
  TokenExpired,
}
