import { Tag } from "@chakra-ui/react";
import { DeviceStatus } from "types";

export function DeviceStatusTag({ status }: { status: DeviceStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case DeviceStatus.Enabled:
        return "green";
      case DeviceStatus.Disabled:
        return "red";
    }
  };
  return <Tag colorScheme={getColorScheme()}>{DeviceStatus[status]}</Tag>;
}
