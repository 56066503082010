import { Badge } from "@chakra-ui/react";
import { CallStatus } from "types";

export function CallStatusBadge({ status }: { status: CallStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case CallStatus.Pending:
        return "yellow";
      case CallStatus.Approved:
        return "green";
      case CallStatus.Rejected:
        return "red";
      case CallStatus.Declined:
        return "gray";
    }
  };

  return <Badge colorScheme={getColorScheme()}>{CallStatus[status]}</Badge>;
}
