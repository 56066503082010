import { getRecoil } from "recoil-nexus";
import { managerState } from "state";

export const getIdToken = async () => {
  const manager = getRecoil(managerState);

  if (!manager) return;

  return manager.token;
};
